import React, {useCallback, useContext, useEffect} from 'react';
// import differenceInDays from "date-fns/differenceInDays";

import {TimelineContext} from "../context/timelineProvider";
import {PageContext} from "../context/pageProvider";
import {MeContext} from "../context/MeProvider";
import {RoomContext} from "../context/roomProvider";
import {WebSocketContext} from "../context/WebSocketProvider";
import {WhiteboardContext} from "../context/WhiteboardProvider";
// import {
//   EVENT_WEBSOCKET_SYNC_TIMELINE,
// } from "../types/TWebSocket";
import {sound_receive} from "../index";
import {API_HOST} from "../config/config";

export const WebSocketListener:React.FC = ({children}) => {

  // const wsRef = useRef<WebSocket | null>(null);

  const {
    addTimeline,
    addTimelineSharedPage,
    addTimelineEntered,
    addTimelineGood,
    attachGoodIntoMessage,
    isOwnPost
  } = useContext(TimelineContext);
  const {setPages} = useContext(PageContext);
  // const {setTmpRoomName} = useContext(TimelineContext);
  const { currentRoom} = useContext(MeContext);
  const {updateRoomMember} = useContext(RoomContext);
  const {addIdeaTags,
    setStickyNotes,
    setTagIdeas,
    // tagIdeas, setTagIdeas,
    // stickyNotes, setStickyNotes
  } = useContext(WhiteboardContext);

  const { setOnMessage} = useContext(WebSocketContext);


  const _eventRecieveWebPages = useCallback((payload:any)=>{
    console.log('_eventRecieveWebPages');

    const result_items = [...payload.pages]
        .sort((a,b) => { return Math.random() - .5; }) // 結果はランダム
    ;

    setPages((prevState:any)=>{
      return {
        ...prevState,
        [payload.word]: result_items
      };
    });

  },[setPages ])


  const requestSoleResult = useCallback((payload:any)=>{

    const f = async () => {

      const timeline_uuid = payload.hash
      const keyword = payload.current_word
      const endpoint = `/v0/sole_result/${timeline_uuid}/${keyword}`

      const res = await fetch(API_HOST + endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: `Bearer ${cookies.access_token}`,
        },
      });

      if (res.status === 200) {
        let json = await res.json()
        // console.log(json);
        _eventRecieveWebPages(json)
      }
    };
    f();
  },[API_HOST, _eventRecieveWebPages])

  // メッセージ受け取り時のイベント
  const _eventRecieveNewMessage = useCallback((payload:any)=>{
    console.log('_eventRecieveNewMessage');

    if(!isOwnPost(payload)){
      addTimeline(payload)
      addIdeaTags(payload.words)
      // console.log(sound_receive)
      updateRoomMember(payload)
      sound_receive.play();
    }

    requestSoleResult(payload)

  },[ isOwnPost, addTimeline, addIdeaTags, updateRoomMember, sound_receive, requestSoleResult])

  const _eventRecieveNewSharedMessage = useCallback((payload:any)=>{
    console.log('_eventRecieveNewSharedMessage');

    if(!isOwnPost(payload)){
      addTimelineSharedPage(payload)
      addIdeaTags(payload.words)
      updateRoomMember(payload)
      sound_receive.play();
    }

    requestSoleResult(payload)

  },[ isOwnPost, addTimelineSharedPage, addIdeaTags, updateRoomMember, sound_receive, requestSoleResult])

  const _eventRecieveGood = useCallback((payload:any)=>{
    console.log('_eventRecieveGood');
    // console.log(payload);

    // if(!isOwnPost(payload)){
    addTimelineGood(payload)
    sound_receive.play();
    // }

  },[ isOwnPost, addTimeline, addIdeaTags, updateRoomMember])


  // 入室時のイベント
  const _eventEnterRoom = useCallback((payload:any)=>{
    console.log('_eventEnterRoom');
    addTimelineEntered(payload)
    updateRoomMember(payload)
  },[addTimelineEntered, updateRoomMember])

  const _eventWhiteboardTags = useCallback((payload:any)=>{
    console.log('_eventWhiteboardTags');
    if(currentRoom.slug === payload.currentRoom.slug){
      setTagIdeas(payload.items)
    }
  },[currentRoom, setTagIdeas])

  const _eventWhiteboardNotes = useCallback((payload:any)=>{
    console.log('_eventWhiteboardNotes');
    if(currentRoom.slug === payload.currentRoom.slug){
      setStickyNotes(payload.items)
    }
  },[currentRoom, setStickyNotes])

  const _eventGoodIntoMessage = useCallback((payload:any)=>{
    console.log('_eventGoodIntoMessage');
    attachGoodIntoMessage(payload)
  },[attachGoodIntoMessage])



  useEffect(() => {

    setOnMessage(() => (event:MessageEvent) => {
      // console.log(event);

      // console.log(event.data);
      const j = JSON.parse(event.data)

      if (j.event_type === 'PUB_HEALTHCHECK') {
        console.log('PUB_HEALTHCHECK')

      } else if (j.event_type === 'CHAT_ENTER_ROOM') {
        console.log('CHAT_ENTER_ROOM')
        _eventEnterRoom(j)

      } else if (j.event_type === 'CHAT_SEND_MESSAGE') {
        console.log('CHAT_SEND_MESSAGE')
        _eventRecieveNewMessage(j)

      } else if (j.event_type === "CHAT_UPDATE_WHITEBOARD_TAGS") {
        console.log('CHAT_UPDATE_WHITEBOARD_TAGS')
        _eventWhiteboardTags(j)
      }else if (j.event_type === "CHAT_UPDATE_WHITEBOARD_NOTES") {
        console.log('CHAT_UPDATE_WHITEBOARD_NOTES')
        _eventWhiteboardNotes(j)
      } else if (j.event_type === 'CHAT_SEND_GOOD') {
        console.log('CHAT_SEND_GOOD')
        _eventRecieveGood(j)
      } else if (j.event_type === "CHAT_UPDATE_PAGES") {
        console.log('CHAT_UPDATE_PAGES')
        _eventRecieveWebPages(j)
      }else if (j.event_type === 'CHAT_GOOD_TO_MESSAGE') {
        console.log('CHAT_GOOD_TO_MESSAGE')
        _eventGoodIntoMessage(j)
      } else if (j.event_type === 'CHAT_SHARE_PAGE') {
        console.log('CHAT_SHARE_PAGE')
        _eventRecieveNewSharedMessage(j)
      }

      // else if (j.event_type === 'CHAT_UPDATE_ROOM') {
      //   console.log('CHAT_UPDATE_ROOM LISTNER')
      //
      //   console.log(j.title);
      //   console.log(j.description);
      //   console.log('CHAT_UPDATE_ROOM LISTNER')
      //   setTmpRoomName(j.title)
      //   setCurrentRoom((prevState:any)=>{
      //     return {
      //       ...prevState,
      //       title: j.title ?? '',
      //       description: j.description ?? ''
      //     }
      //   })
      //
      //   const room_slug = j.currentRoom.slug
      //
      //   let isSameMember = false
      //   // @ts-ignore
      //   if(room_slug !== undefined && roomUsers[room_slug] !== undefined){
      //     // @ts-ignore
      //     roomUsers[room_slug].forEach((user:any,idx:number) => {
      //       if (user.name === j.user.name){
      //         isSameMember = true
      //       }
      //     })
      //   }
      //
      //
      //   if(!isSameMember ){
      //     setRoomUsers((prevState:any)=>{
      //       const prev_users = prevState[room_slug]
      //       if(prev_users === undefined){
      //         return {
      //           ...prevState,
      //           [room_slug] : [j.user]
      //         }
      //       }
      //       return {
      //         ...prevState,
      //         [room_slug] : [...prev_users, j.user]
      //       }
      //     })
      //   }
      //
      //
      // } else if (j.event_type === "CHAT_UPDATE_PAGES") {
      //   console.log('CHAT_UPDATE_PAGES')
      //
      //   // console.log(j.word)
      //   // console.log(j.pages)
      //   const result_items = [...j.pages]
      //       .sort((a,b) => { return Math.random() - .5; })
      //   ;
      //
      //   setPages((prevState:any)=>{
      //     return {
      //       ...prevState,
      //       [j.word]: result_items
      //     };
      //   });
      //
      //
      // } else if (j.event_type === "CHAT_INIT") {
      //   console.log('CHAT_INIT')
      //   // WSSyncWhiteboardNotes(j.items)
      //   rwsRef?.current?.send(JSON.stringify({
      //     event_type: EVENT_WEBSOCKET_SYNC_TIMELINE,
      //     time: new Date(),
      //     user: me,
      //     currentRoom: currentRoom,
      //     timelines: timeline[currentRoom.slug],
      //     ideatags: tagIdeas,
      //     stickynotes: stickyNotes,
      //   } as EVENT_WEBSOCKET_SYNC_TIMELINE))
      // } else if (j.event_type === "PUB_SYNC_TIMELINE") {
      //   console.log('PUB_SYNC_TIMELINE')
      //   // WSSyncWhiteboardNotes(j.items)
      //   // setTimeline((prevState:any)=>{
      //   //   // 重複は削除
      //   //   const mergedMap= new Map<number, any>([
      //   //       ...prevState[currentRoom.slug],
      //   //     ...j.timelines
      //   //   ].map(i => [i.id, i]));
      //   //   return {
      //   //     ...prevState,
      //   //     [currentRoom.slug] : Array.from(mergedMap.values()).sort((a, b) => a.hash - b.hash)
      //   //   }
      //   // })
      //
      //   // setTagIdeas((prevState:any[])=>{
      //   //   return Array.from(new Set([...prevState, ...j.ideatags]))
      //   // })
      //   //
      //   // setStickyNotes((prevState:TSourceStickyNote[])=>{
      //   //   const mergedMap = new Map<number, TSourceStickyNote>([...prevState, ...j.stickynotes].map(i => [i.id, i]));
      //   //   return Array.from(mergedMap.values()).sort((a, b) => a.id - b.id);
      //   // })
      //
      // }
      //
      //
      // if(j.type === "ROOM"){
      //
      // }else if(j.type === "PENDING"){
      //   // setWips((prevState)=>{
      //   //   return [
      //   //     {
      //   //       name: j.id,
      //   //       body: j.body,
      //   //       date: new Date(j.t),
      //   //     }
      //   //   ];
      //   // });
      // } else if(j.type === "CLEAR"){
      //   // setWips((prevState)=>{
      //   //   return [];
      //   // });
      // } else if(j.type === "PAGES"){
      //
      //
      // } else if(j.type === "MESSAGE"){
      //
      // }
    })

  }, [setOnMessage,
    _eventEnterRoom,
    _eventRecieveNewMessage,
    _eventRecieveGood,
    _eventRecieveWebPages,
    _eventWhiteboardTags,
    _eventWhiteboardNotes,
    _eventGoodIntoMessage,
    _eventRecieveNewSharedMessage
  ]);

  // 'me', 'ws_send',
  //     'addIdeaTags', 'setPages', 'setTmpRoomName',
  //     'currentRoom', 'setCurrentRoom',
  //     'roomUsers', 'setRoomUsers',
  //     'stickyNotes', 'setStickyNotes',
  //     'tagIdeas', 'setTagIdeas',
  //     'timeline', 'setTimeline'


  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden && wsRef.current?.readyState !== WebSocket.OPEN) {
  //       console.log('WebSocket is not connected. Attempting to reconnect...');
  //       wsRef.current?.close();
  //       const wsInstance = new WebSocket(wsRef.current?.url!);
  //       wsRef.current = wsInstance;
  //     }
  //   };
  //
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);


  return (
    <>{children}</>
  )
}
