import * as React from 'react';
import {useEffect} from "react";
import {ICurrentRoom, IUser, TChatLog} from "../types/TChat";


type Props = {
    clientKey: string;
    children?: React.ReactNode;
};

interface ContextProps {
    clientKey: string;
    me: IUser;
    setMe: (data:any) => void;
    currentRoom:ICurrentRoom,
    setCurrentRoom: (data:any) => void;
    contextMenuText: string,
    setContextMenuText: (data:any) => void,
    contextMenuChatLog: TChatLog,
    setContextMenuChatLog: (data:any) => void,
}

// Contextを生成
export const MeContext = React.createContext<ContextProps>({
    clientKey:"",
    me: {} as IUser,
    setMe: (data) => null,
    currentRoom: {} as ICurrentRoom,
    setCurrentRoom: (data) => null,
    contextMenuText: '',
    setContextMenuText: (data) => null,
    contextMenuChatLog: {} as TChatLog,
    setContextMenuChatLog: (data) => null,
});

/**
 * MeProvider
 */
const MeProvider: React.FC<Props> = ({clientKey, children }) => {

    const [me, setMe] = React.useState<IUser>({} as IUser);
    const [currentRoom, setCurrentRoom] = React.useState<any>(null);
    const [contextMenuText, setContextMenuText] = React.useState<string>("");
    const [contextMenuChatLog, setContextMenuChatLog] = React.useState<TChatLog>({} as TChatLog);
    // 言語データを更新

    useEffect(() => {
        console.log(clientKey);
        setMe((prevState: any) => {
            return {
                ...prevState,
                client_key: clientKey,
            }
        })
    }, [clientKey, setMe]);

    return (
        <MeContext.Provider value={{
            clientKey,
            me, setMe,
            currentRoom, setCurrentRoom,
            contextMenuText, setContextMenuText,
            contextMenuChatLog, setContextMenuChatLog,
        }}>
            {children}
        </MeContext.Provider>
    );
};
export default MeProvider;