import React, {useContext, useMemo} from 'react';
import { useDrop} from 'react-dnd'
import { formatDistanceToNow } from 'date-fns';
import { ja } from 'date-fns/locale'

import kuromoji from 'kuromoji'
import {sha256} from "../Form/ChatForm";
// var TinySegmenter = require('../../../../../node_modules/tiny-segmenter/lib/index');
// const tinySegmenter = new TinySegmenter();

import {
  EVENT_WEBSOCKET_SHARE_PAGE,
  TYPE_WEBSOCKET_SHARE_PAGE
} from "../../../../types/TWebSocket";
import {TChatLog} from "../../../../types/TChat";
// import {IUser} from "../../../../types/TChat";

import {MeContext} from "../../../../context/MeProvider";
import {WebSocketContext} from "../../../../context/WebSocketProvider";
import {TimelineContext} from "../../../../context/timelineProvider";

import {useChatMessage} from "../../../../services/useChatMessage";
import {ProfileIcon} from "./ProfileIcon";

import styles from './Message.module.css'


interface Props {
  chatLog: TChatLog
}

export const MessageDropWrap:React.FC<Props> = ({chatLog, children }) => {

  const {me} = useContext(MeContext);
  const {classname_chat_detail, isMine} = useChatMessage({me, chatLog})

  const profile_icon = useMemo(()=>{
    if(isMine){
      return null
    }
    return <ProfileIcon chatLog={chatLog} />
  },[isMine, chatLog])

  return (
      <MessageDropWrapOuter>
        <span className={styles.time_block}>
          {formatDistanceToNow(chatLog.date, { locale: ja })}
        </span>

        <div className={classname_chat_detail}>
          {profile_icon}
          <div className={styles.body}>
            {children}
          </div>
        </div>
      </MessageDropWrapOuter>
  );
}


export const MessageDropWrapOuter:React.FC = ({ children }) => {

  const {me, currentRoom} = useContext(MeContext);

  const {setTimeline} = useContext(TimelineContext);
  // const {addTimelineSharedPage} = useContext(TimelineContext);
  const {rwsRef} = useContext(WebSocketContext);

  const [, dropRef] = useDrop({
    accept: [
      "BOX",
    ],
    drop: (item: any, target) => {
      // console.log(item)
      if (item.type === "BOX") {
        console.log('DROPPED')

        sha256(item.title)
            .then((hash) => {
              console.log(hash)

              setTimeline((prevState:any)=>{
                return {
                  ...prevState,
                  [currentRoom.slug]: [
                    ...prevState[currentRoom.slug],
                    {
                      chatlog_type: 'PAGE',
                      hash: hash,
                      user: me,
                      page: item,
                      date: new Date(),
                    }
                  ]
                };
              });

              // Using: Kuromoji
              kuromoji.builder({ dicPath: "/kuromoji_dict" })
                  .build((err, tokenizer) => {
                        if(err){
                          console.log("err")
                          console.log(err)
                        } else {
                          const tokens = tokenizer.tokenize(item.title)
                          // console.log(tokens)

                          const filtered_words = tokens.filter((w:any,idx:number)=>{
                            if (w.pos === "名詞"
                                && (w.pos_detail_1 === "固有名詞"
                                    || w.pos_detail_1 === "一般")
                            ){
                              return true
                            }
                            return false
                          })

                          const words = filtered_words.map((w,idx)=>{
                            return w.surface_form
                          })

                          // const payload = {
                          //   hash:hash,
                          //   user:me,
                          //   page:item,
                          //   date_str:date_str,
                          //   words:words,
                          //   current_word: words[0] ?? null
                          // }
                          // addTimelineSharedPage(payload)

                          rwsRef?.current?.send(JSON.stringify({
                            event_type: EVENT_WEBSOCKET_SHARE_PAGE,
                            time: new Date(),
                            user: me,
                            currentRoom: currentRoom,
                            hash: hash,
                            page:item,
                            words: words,
                            current_word: words[0] ?? null
                          } as TYPE_WEBSOCKET_SHARE_PAGE))

                          setTimeline((prevState:any)=>{

                            return {
                              ...prevState,
                              [currentRoom.slug]: prevState[currentRoom.slug].map((item:any,idx:number)=>{
                                if(hash === item.hash){
                                  return {
                                    ...item,
                                    words: words,
                                    current_word: words[0] ?? null
                                  }
                                }
                                return item
                              })
                            };
                          });

                        }
                      }
                  )

            })


        // setShareData(item);

        // const segs = tinySegmenter.segment(item.title);
        // console.log(segs);
        // const reg = new RegExp(/^[あ-んア-ン、。 　_,-/.()[]=+\*\?<>]$/,'g');
        // let filtered_segs = segs.filter((ele:any, idx:number)=>{
        //   if(ele.match(reg)){
        //     return false;
        //   }
        //   return true;
        // })
        // console.log(filtered_segs);

      }
    },
    canDrop: (item, target) => {
      if (item.type === "BOX") {
        return true;
      }
      return true;
    },
    hover:(item,monitor) => {
    },
    collect: (monitor) => {
      return {
      }
    }
  })

  return (
      <div ref={dropRef} className={styles.chat_container}>
        {children}
      </div>
  )
}
