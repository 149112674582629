import * as React from 'react';
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {WEBSOCKET_BASE_URL} from "../config/config";
import ReconnectingWebSocket from "reconnecting-websocket";
import {MeContext} from "./MeProvider";
// import {WEBSOCKET_BASE_URL} from "../config/config";

interface ContextProps {
    rwsRef: React.RefObject<ReconnectingWebSocket> | null;
    setOnMessage: React.Dispatch<React.SetStateAction<(event: MessageEvent) => void>>;
}

export const WebSocketContext = createContext({} as ContextProps);

type Props = {
    children?: React.ReactNode;
};


export const WebSocketProvider:React.FC<Props> = ({ children}) => {

    const {clientKey} = useContext(MeContext)

    const rwsRef = useRef<ReconnectingWebSocket | null>(null);

    const [onMessage, setOnMessage] = useState(() => (event: MessageEvent) => console.log('messaged'));
    const onMessageRef = useRef(onMessage);

    useEffect(() => {
        onMessageRef.current = onMessage;
    }, [onMessage]);


    useEffect(() => {
        const options = {
            // 先程の設定
        };
        rwsRef.current = new ReconnectingWebSocket(`${WEBSOCKET_BASE_URL}/${clientKey}`, [], options);

        rwsRef.current.addEventListener('open', () => console.log('connected'));
        rwsRef.current.addEventListener('close', () => console.log('disconnected'));

        rwsRef.current.addEventListener('message', (event) => {
            onMessageRef.current(event);
        });

        return () => {
            if (rwsRef.current) {
                rwsRef.current.close();
            }
        };
    }, [clientKey]);

    return (
        <WebSocketContext.Provider value={{
            rwsRef,
            setOnMessage
        }}>
            {children}
        </WebSocketContext.Provider>
    )
}
