import React from 'react';

import {TChatLog} from "../../../../types/TChat";

// import {MeContext} from "../../../../context/MeProvider";
// import {useChatMessage} from "../../../../services/useChatMessage";

import {formatDistanceToNow} from "date-fns";
import {ja} from "date-fns/locale";
import wrap_styles from './Message.module.css'

interface Props {
  chatLog: TChatLog
}

export const MessageBot:React.FC<Props> = ({chatLog }) => {

  // const {me} = useContext(MeContext);
  // const {isMine} = useChatMessage({me, chatLog})

  return (
    <div className={wrap_styles.chat_container}>
      <span className={wrap_styles.time_block}>
        {formatDistanceToNow(chatLog.date, { locale: ja })}
      </span>
      <p style={{
        margin:"-10px 0",
        padding:"0",
        display:"block",
        textAlign:"center",
        fontSize:'11px',
        color:'#666666',
      }}>
        {chatLog.body}
      </p>
    </div>
  );
}

