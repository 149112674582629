import * as React from 'react';
import {useCallback, useContext} from "react";
import {TChatLog} from "../types/TChat";
import {MeContext} from "./MeProvider";

interface TimelineProviderProps {
    // timeline: any;
}

type Props = {
    children?: React.ReactNode;
};

interface ContextProps {
    timeline: {
        [slug: string]: TChatLog[]
    };
    setTimeline: (data:any) => void;
    rooms:[],
    setRooms: (data:any) => void;
    tmpRoomName:string,
    setTmpRoomName: (data:any) => void;
    addTimeline: Function;
    addTimelineSharedPage: Function;
    addTimelineGood: Function;
    addTimelineEntered: Function;
    attachGoodIntoMessage: Function;
    isOwnPost: Function;

}

// Contextを生成
export const TimelineContext = React.createContext<ContextProps>({
    timeline: {},
    setTimeline: (data) => null,
    rooms: [],
    setRooms: (data) => null,
    tmpRoomName:'',
    setTmpRoomName: (data) => null,
    addTimeline: (payload:any) => null,
    addTimelineSharedPage: (payload:any) => null,
    addTimelineGood: (payload:any) => null,
    addTimelineEntered: (payload:any) => null,
    attachGoodIntoMessage: (payload:any) => null,
    isOwnPost: (payload:any) => Boolean,
});

/**
 * TimelineProvider
 */
const TimelineProvider: React.FC<TimelineProviderProps> = ({ children }:Props) => {
    const [timeline, setTimeline] = React.useState<any>({});
    const [rooms, setRooms] = React.useState<[]>([]);
    const {currentRoom, setCurrentRoom} = useContext(MeContext);
    const [tmpRoomName, setTmpRoomName] = React.useState<string>('');
    // 言語データを更新
    React.useEffect(() => {
        // setTimeline(getLocaleData(timeline));
    }, [timeline]);


    /**
     * 投稿を追加
     */
    const addTimeline = useCallback((payload:any)=>{
        if(currentRoom.slug !== payload.currentRoom.slug){
            return
        }
        setTimeline((prevState:any)=>{
            return {
                ...prevState,
                [currentRoom.slug]: [
                    ...prevState[currentRoom.slug],
                    {
                        chatlog_type: 'MESSAGE',
                        hash: payload.hash,
                        user: payload.user,
                        body: payload.message,
                        date: new Date(payload.date_str),
                        good_length: 0,
                        words: payload.words,
                        current_word: payload.current_word,
                    }
                ]
            };
        })
    },[currentRoom, setTimeline])


    /**
     * シェア投稿を追加
     */
    const addTimelineSharedPage = useCallback((payload:any)=>{
        if(currentRoom.slug !== payload.currentRoom.slug){
            return
        }
        setTimeline((prevState:any)=>{
            return {
                ...prevState,
                [currentRoom.slug]: [
                    ...prevState[currentRoom.slug],
                    {
                        chatlog_type: 'PAGE',
                        hash: payload.hash,
                        user: payload.user,
                        page: payload.page,
                        date: new Date(payload.date_str),
                        good_length: 0,
                        words: payload.words,
                        current_word: payload.current_word,
                    }
                ]
            };
        });
    },[currentRoom, setTimeline])


    /**
     * イイネを投稿として保存する。
     */
    const addTimelineGood = useCallback((payload:any)=>{
        if(currentRoom.slug !== payload.currentRoom.slug){
            return
        }
        setTimeline((prevState:any)=>{
            return {
                ...prevState,
                [currentRoom.slug]: [
                    ...prevState[currentRoom.slug],
                    {
                        chatlog_type: 'GOOD',
                        user: payload.user,
                        date: new Date(payload.date_str),
                        good_length: 0
                    }
                ]
            };
        })

    },[currentRoom, setTimeline])


    /**
     * 部屋に入ったことを投稿として登録する
     */
    const addTimelineEntered = useCallback((payload:any)=>{

        if(!currentRoom || !currentRoom.slug){
            return
        }
        if(currentRoom.slug !== payload.currentRoom.slug){
            return
        }

        if(timeline[currentRoom.slug] === undefined){
            setTimeline((prevState:any)=>{
                return {
                    ...prevState,
                    [currentRoom.slug]: [
                        {
                            chatlog_type: "BOT",
                            body: payload.user.name + ' さんが参加しました。',
                            user: payload.user,
                            date: new Date(payload.date_str),
                        }
                    ]
                }
            })
        }else{
                setTimeline((prevState:any)=>{
                    return {
                        ...prevState,
                        [currentRoom.slug]: [
                            ...prevState[currentRoom.slug],
                            {
                                chatlog_type: "BOT",
                                body: payload.user.name + ' さんが参加しました。',
                                user: payload.user,
                                date: new Date(payload.date_str),
                            }
                        ]
                    };
                })
        }

    },[currentRoom, timeline, setTimeline])


    /**
     * 投稿にイイネする
     */
    const attachGoodIntoMessage = useCallback((payload:any)=>{
        if(currentRoom.slug !== payload.currentRoom.slug){
            return
        }
        setTimeline((prevState:any)=>{
            return {
                ...prevState,
                [currentRoom.slug]: [
                    ...prevState[currentRoom.slug].map((item:any,idx:number)=>{
                        if(item.hash === payload.chatLog.hash ){
                            return {
                                ...item,
                                good_length: (item.good_length ?? 0) + 1
                            }
                        }
                        return item
                    }),
                ]
            };
        });

    },[currentRoom, setTimeline])


    /**
     * 自分の投稿か調べる
     */
    const isOwnPost = useCallback((payload:any)=>{
        if(timeline[currentRoom.slug] === undefined){
            return false
        }
        if(currentRoom.slug !== payload.currentRoom.slug){
            return false
        }

        let _isOwnPostFlg = false
        timeline[currentRoom.slug].forEach((chatlog:any,idx:number) => {
            // console.log(chatlog, payload);
            if (chatlog !== undefined && payload !== undefined && chatlog.hash === payload.hash){
                _isOwnPostFlg = true
            }
        })
        return _isOwnPostFlg
    },[timeline, currentRoom])

    return (
        <TimelineContext.Provider value={{
            timeline, setTimeline,
            rooms, setRooms,
            tmpRoomName,
            setTmpRoomName,
            addTimeline,
            addTimelineSharedPage,
            addTimelineGood,
            addTimelineEntered,
            attachGoodIntoMessage,
            isOwnPost
        }}>
            {children}
        </TimelineContext.Provider>
    );
};
export default TimelineProvider;