import * as React from 'react';
import {createContext, useCallback, useState} from "react";
import {TSourceStickyNote} from "../views/Chat/Components/Whiteboard/Types/TSourceStickyNote";
import {useWebSocket} from "../services/useWebSocket";

interface ContextProps {
  stickyNotes:TSourceStickyNote[],
  setStickyNotes: Function,
  tagIdeas: string[],
  setTagIdeas: Function,
  editingNote: TSourceStickyNote | null
  setEditingNote: Function,
  updateNote: Function,
  addNote: Function,
  addIdeaTags: Function,
  removeIdeaTag: Function,
  draggingObject:TSourceStickyNote | null,
  setDraggingObject: Function,
}

export const WhiteboardContext = createContext({} as ContextProps);

type Props = {
    children?: React.ReactNode;
};

export const WhiteboardProvider = ({children}: Props) => {

    const {WSSyncWhiteboardTags, WSSyncWhiteboardNotes} = useWebSocket();
    const [stickyNotes, setStickyNotes] = useState<TSourceStickyNote[]>([
        {type:"STICKY_NOTE", id:1,x: 200, y: 200, width: 150, height: 150, color: '#FFD700', text: 'メモ'},
    ]);
    const [tagIdeas, setTagIdeas] = useState<string[]>([]);

    const [editingNote, setEditingNote] = useState<TSourceStickyNote|null>(null);
    const [draggingObject, setDraggingObject] = useState<TSourceStickyNote | null>(null);


    const updateNote = useCallback((str:string, editedNote:TSourceStickyNote)=>{

        const updatedStickyNotes = stickyNotes.map((prevNote)=>{
                if(prevNote.id === editedNote.id){
                    return {...prevNote, text: str}
                }else{
                    return prevNote
                }
            }
        )

        setStickyNotes(updatedStickyNotes)
        WSSyncWhiteboardNotes(updatedStickyNotes)

        setEditingNote(null);

    },[stickyNotes, setStickyNotes, WSSyncWhiteboardNotes])

    const addNote = useCallback((text:string, x:number, y: number)=>{

        const updatedStickyNotes = [...stickyNotes,
            {
                id: x * y,
                text: text,
                x: x,
                y: y,
                width: 150,
                height: 150
            } as TSourceStickyNote
        ]
        setStickyNotes(updatedStickyNotes)
        WSSyncWhiteboardNotes(updatedStickyNotes)

    },[stickyNotes, setStickyNotes, WSSyncWhiteboardNotes])


    const addIdeaTags = useCallback((strs:string[])=>{
        setTagIdeas(prevState => {
            return Array.from(new Set([...prevState, ...strs]))
        })
    },[setTagIdeas])

    const removeIdeaTag = useCallback((text:string)=>{
        const updatedIdeaTag = [...tagIdeas].filter((prevTag)=>{
            return prevTag !== text
        })
        setTagIdeas(updatedIdeaTag)
    },[tagIdeas, setTagIdeas])


    return (
        <WhiteboardContext.Provider value={{
            stickyNotes, setStickyNotes,
            tagIdeas, setTagIdeas,
            editingNote, setEditingNote,
            draggingObject, setDraggingObject,
            updateNote,
            addNote,
            addIdeaTags,
            removeIdeaTag
        }}>
            {children}
        </WhiteboardContext.Provider>
    )

}
