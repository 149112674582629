import React, {useContext, useEffect} from 'react';
// import differenceInDays from "date-fns/differenceInDays";

import {Message, MessageBlank} from "./Message/Message";

import {TimelineContext} from "../../../context/timelineProvider";
import {MeContext} from "../../../context/MeProvider";

import styles from './ChatContent.module.css'
import {TChatLog} from "../../../types/TChat";



export const ChatContent:React.FC =() => {

  const {me, currentRoom} = useContext(MeContext);
  const {timeline, setTimeline} = useContext(TimelineContext);

  useEffect(()=>{
    if(undefined === timeline[currentRoom.slug]){
      setTimeline((prevState:any)=>{
        return {
          ...prevState,
          [currentRoom.slug] : [
          //   {
          //   user: {
          //     name: "BOT",
          //     icon:{
          //       id: Number(200).toString(16)
          //         + Number(200).toString(16)
          //         + Number(200).toString(16),
          //       r:200,
          //       g:200,
          //       b:200
          //     }
          //   },
          //   chatlog_type: "BOT",
          //   body: me.name + 'さんが参加しました。',
          //   date: new Date()
          // }
          ]
        };
      });
    }

    let ContainerDOM = document.getElementById("chat_container");
    if(ContainerDOM !== null){
      // list.scrollTop = list.offsetHeight;
      ContainerDOM.scrollIntoView({
        // behavior: "smooth",
        behavior: "auto",
        block: "end",
        inline: "nearest"
      });
    }
  },[timeline, setTimeline, currentRoom, me])


  // const [chatLogs, setChatLogs] = useState<TChatLog[]>([{
  //   name: "BOT",
  //   body: 'Hey!! '+ username,
  //   date: new Date(),
  // }]);
  // const [wips, setWips] = useState<TChatLog[]>([] as TChatLog[]);

  return (
    <>
      {undefined === timeline[currentRoom.slug]
        ? null
        : (<ChatContainer />)
      }
    </>
  );
}

const ChatContainer:React.FC =() => {

  const {currentRoom} = useContext(MeContext);
  const {timeline} = useContext(TimelineContext);

  return (
    <section id="chat_container" className={styles.container}>
      {
        timeline[currentRoom.slug].map((chatLog:TChatLog, index:number) => {
          if(chatLog.body !== ''){
            return (<Message
              key={index}
              chatLog={chatLog}
            />)
          }
          return null;
        })
      }
      <MessageBlank key={'blank'}></MessageBlank>

    </section>
  );
}
