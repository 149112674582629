import * as React from 'react';
import {createContext, useState} from "react";

interface ContextProps {
  modalStates: any,
  setModalStates: Function,
  openModalAttachment: boolean,
  setOpenModalAttachment: Function,
  attachmentData: string,
  setAttachmentData: Function,
}

export const ModalContext = createContext({} as ContextProps);

type Props = {
  children?: React.ReactNode;
};

export const ModalProvider = ({children}: Props) => {

  // モーダルの開閉状態
  const [modalStates, setModalStates] = useState({});
  const [openModalAttachment, setOpenModalAttachment] = useState<boolean>(false);
  const [attachmentData, setAttachmentData] = useState<string>('');

  return (
    <ModalContext.Provider value={{
      modalStates,
      setModalStates,
      openModalAttachment,
      setOpenModalAttachment,
      attachmentData,
      setAttachmentData
    }}>
      {children}
    </ModalContext.Provider>
  )
}
