import React, {useContext} from 'react';

import {TChatLog} from "../../../../types/TChat";

import {MeContext} from "../../../../context/MeProvider";
import {useChatMessage} from "../../../../services/useChatMessage";
import {MessageDropWrap} from "./MessageDropWrap";

import styles from './MessageGood.module.css'

interface Props {
  chatLog: TChatLog
}

export const MessageGood:React.FC<Props> = ({chatLog }) => {

  const {me} = useContext(MeContext);
  const {isMine} = useChatMessage({me, chatLog})

  return (
    <MessageDropWrap chatLog={chatLog}>
      <div className={isMine
        ? styles.my_good_box
        : styles.good_box
      }>
        <img src="/img/icn_good.svg" alt=""/>
      </div>
    </MessageDropWrap>
  );
}


// export const MessageText2:React.FC<Props> = ({chatLog,pages,user}) => {
//
//   const [, dropRef] = useDrop({
//     accept: [
//       "BOX",
//     ],
//     drop: (item: any, target) => {
//       // console.log(item)
//       if (item.type === "BOX") {
//         console.log('DROPPED')
//         // setShareData(item);
//
//         ws_send(JSON.stringify({
//           event_type: EVENT_WEBSOCKET_SHARE_PAGE,
//           time: new Date(),
//           user: {name:'AAA'},
//           currentRoom: currentRoom
//           message: item,
//         }))
//       }
//     },
//     canDrop: (item, target) => {
//       if (item.type === "BOX") {
//         return true;
//       }
//       return true;
//     },
//     hover:(item,monitor) => {
//     },
//     collect: (monitor) => {
//       return {
//       }
//     }
//   })
//
//   return (
//     <div className={styles.message} ref={dropRef}>
//       <div className={styles.chat_container}>
//         <div className={styles.meta}>
//           <span style={{color:
//               user.name === chatLog.user.name ? "red" : 'inherit'
//           }} className={styles.name}>{chatLog.user.name}</span>
//           <span className={styles.time}>{formatDistanceToNow(chatLog.date, { locale: ja })}</span>
//           <span>書き込み中</span>
//         </div>
//         <p className={styles.body} style={{backgroundColor:"#DDDDDD;",color: "#999999;"}}>{chatLog.body}</p>
//
//       </div>
//     </div>
//   );
// }
