import React, {useContext, useMemo} from 'react';

import {MeContext} from "../../../context/MeProvider";

import {RoomHeader} from "./Header/RoomHeader";
import {ChatContent} from "./ChatContent";
import {ChatForm} from "./Form/ChatForm";

import styles from './RoomFrame.module.css'
import {RoomContext} from "../../../context/roomProvider";
import {ExtraSwitchButton} from "./Extra/ExtraSwitchButton";
import {WhiteboardContent} from "./Whiteboard/WhiteboardContent";


export const RoomFrame:React.FC = () => {

  const {me, currentRoom} = useContext(MeContext);
  const {extraMode} = useContext(RoomContext);

  const whiteboard_content = useMemo(()=>{
    if(extraMode === 'WHITEBOARD'){
      return (
        <WhiteboardContent />
      )
    }
    return null
  },[extraMode])

  if(me.name !== undefined
    && me.name !== '' && currentRoom !== null
  ){
    return (<>
        <RoomHeader />
        <ExtraSwitchButton/>
        <ChatContent />
        {whiteboard_content}
        <ChatForm />
    </>)
  }

  return (
    <div className={styles.container}>
    </div>
  );
}
