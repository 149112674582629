import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PageProvider from "./context/pageProvider";
import TimelineProvider from "./context/timelineProvider";
import MeProvider from "./context/MeProvider";
import RoomProvider from "./context/roomProvider";
import {ModalProvider} from "./context/ModalProvider";
import {WhiteboardProvider} from "./context/WhiteboardProvider";
import {WebSocketProvider} from "./context/WebSocketProvider";

import {App} from "./views/App";

import './index.css';
import {v4 as uuidv4} from "uuid";

export const sound = new Audio();
export const sound_receive = new Audio();
function init() {
  sound.preload = "auto";
  sound.src = "/sound/po_me.mp3";
  sound.load();
  sound.addEventListener("ended", function () {
    sound.currentTime = 0;
  }, false);
  sound_receive.preload = "auto";
  sound_receive.src = "/sound/po_you.mp3";
  sound_receive.load();
  sound_receive.addEventListener("ended", function () {
    sound_receive.currentTime = 0;
  }, false);
}
init();
const clientKey = uuidv4();

ReactDOM.render(
  <React.StrictMode>
    <ModalProvider>
      <MeProvider clientKey={clientKey}>
        <RoomProvider>
          <WebSocketProvider>
            <PageProvider>
              <WhiteboardProvider>
                <TimelineProvider>
                  <DndProvider backend={HTML5Backend}>
                    <App />
                  </DndProvider>
                </TimelineProvider>
              </WhiteboardProvider>
            </PageProvider>
          </WebSocketProvider>
        </RoomProvider>
      </MeProvider>
    </ModalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
