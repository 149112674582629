import React, {useState, useContext} from 'react';
import {WhiteboardContext} from "../../../../context/WhiteboardProvider";


export const StickyNoteTextarea:React.FC = () => {

  const {
    updateNote,
    editingNote
  } = useContext(WhiteboardContext)

  const [textareaValue, setTextareaValue] = useState(editingNote?.text);

  if(!editingNote){
    return null
  }


  const handleBlur = () => {
    updateNote(textareaValue, editingNote)
    setTextareaValue('');
  };

  const handleChange = (e:any) => {
    setTextareaValue(e.target.value);
  };

  return (
    <textarea
      style={{
        position: 'absolute',
        left: `${editingNote.x + 5}px`,
        top: `${editingNote.y + 5}px`,
        width: `${editingNote.width - 10}px`,
        height: `${editingNote.height - 10}px`,
        background: 'rgba(233, 235, 156, 1)',
        border: 'none',
        padding: '5px',
      }}
      placeholder="メモ"
      value={textareaValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
