import React, {useCallback, useContext, useMemo} from 'react';

import {TChatLog} from "../../../../types/TChat";
import {API_HOST} from "../../../../config/config";

import {PageContext} from "../../../../context/pageProvider";
import {TimelineContext} from "../../../../context/timelineProvider";
import {MeContext} from "../../../../context/MeProvider";

import styles from './Keywords.module.css'

interface Props {
  chatLog: TChatLog
}


export const Keywords:React.FC<Props> = ({chatLog}) => {

  // chatLog.words = ['大阪','京都','兵庫']
  // const current_word = '111';

  const seeKeywords = useMemo(()=>{
    if(chatLog.words === undefined){
      return null;
    }
    if(chatLog.words.length >= 2){
      return (<p className={styles.seeKeywords}>他
        <strong>
          {chatLog.words.length - 1}キーワード
        </strong>
        を見る
        <img className={styles.icn_arrow_more} src="/img/icn_arrow_more.svg" alt=""/>
      </p>)
    }
  },[chatLog.words]);

  return (
    <div className={styles.Keywords}>
      {seeKeywords}
      <KeywordOverlay chatLog={chatLog} />
    </div>
  );
}


export const KeywordOverlay:React.FC<Props> = ({chatLog}) => {

  const {pages,setPages} = useContext(PageContext);
  const {setTimeline} = useContext(TimelineContext);
  const {me, currentRoom} = useContext(MeContext);


  // キーワードを切り替え
  const handleClick = useCallback((hash:any, word:string)=>{

    setTimeline((prevState:any)=>{

      return {
        ...prevState,
        [currentRoom.slug]: prevState[currentRoom.slug].map((item:any,idx:number)=>{
          if(item.hash === undefined || hash === undefined){
            return item
          }
          if(item.hash === hash){
            return {
              ...item,
              current_word: word
            }
          }
          return item

        })
      };
    });

    if(pages[word] === undefined || pages[word].length === 0){
      const f = async () => {
        const obj = {
          user: me,
          word:word
        };
        const body = JSON.stringify(obj);

        const res_keyword = await fetch(API_HOST+'/v0/page_search/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            //   Authorization: `Bearer ${cookies.access_token}`,
          },
          body
        });

        if (res_keyword.status === 200) {
          let json = await res_keyword.json()
          console.log(json);

          if( json !== null){

            const result_items = [...json]
              .sort((a,b) => { return Math.random() - .5; })
            ;

            setPages((prevState:any)=>{
              return {
                ...prevState,
                [word]: result_items ?? []
              };
            });
          }

        }
      };
      f();
    }

  },[pages, setPages, setTimeline,currentRoom.slug,me])

  if(chatLog.words === undefined || chatLog.words.length <= 1){
    return null
  }

  return (
    <nav className={styles.KeywordOverlay}>
      <ul>
        {
          chatLog.words.map((word:string, idx:number)=>{
            return (<li
              key={idx}
              onClick={(e)=>{
                e.preventDefault();
                handleClick(chatLog.hash, word)
              }}
            ><span>{word}</span></li>)
          })

        }
      </ul>
    </nav>
  );
}
