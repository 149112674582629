

// LOCAL
// export const API_HOST = "http://localhost:8000"
// export const WEBSOCKET_BASE_URL = "ws://localhost:8008/ws"

// PROD
export const API_HOST = "https://sole.socialis.co.jp:8000"
export const WEBSOCKET_BASE_URL = "wss://solews.socialis.co.jp:443/ws"




// export const API_HOST = "https://sole.socialis.co.jp:8000"
// export const WEBSOCKET_ENDPOINT = "wss://sole.socialis.co.jp:8000/ws"
// export const API_HOST = "https://18.181.24.112:8000"
// export const WEBSOCKET_ENDPOINT = "wss://18.181.24.112:8000/ws"
// export const WS = new WebSocket(WEBSOCKET_ENDPOINT);
