import React, {useContext} from 'react';

import {TChatLog} from "../../../../types/TChat";

import {MeContext} from "../../../../context/MeProvider";
import {useChatMessage} from "../../../../services/useChatMessage";
import {getProfileBgStyle} from "../../ChatView";

// import styles from './ProfileIcon.module.css'

interface Props {
  chatLog: TChatLog
}

export const ProfileIcon:React.FC<Props> = ({chatLog}) => {

  const {me} = useContext(MeContext);
  const {
    classname_meta,
    classname_profile_icon,
    classname_name,
  } = useChatMessage({me, chatLog})

  return (
    <div className={classname_meta}>
      <span className={classname_name}>
          {chatLog.user.name}
      </span>
      <i className={classname_profile_icon}
         style={getProfileBgStyle(chatLog.user)}
      ><img src="/img/icn_profile.svg"
            alt={chatLog.user.name}
            title={chatLog.user.name}
      /></i>

    </div>
  );

}

