import React, {useContext, useState} from 'react';
import {TChatLog} from "../../../../types/TChat";

import styles from './Good.module.css'
import {useChatMessage} from "../../../../services/useChatMessage";
import {MeContext} from "../../../../context/MeProvider";
import {
  EVENT_WEBSOCKET_GOOD_TO_MESSAGE,
  TYPE_WEBSOCKET_GOOD_TO_MESSAGE,
} from "../../../../types/TWebSocket";
import {WebSocketContext} from "../../../../context/WebSocketProvider";

interface Props {
  chatLog: TChatLog
}

export const Good:React.FC<Props> = ({chatLog }) => {

  const [good, setGood] = useState(false)
  const {me,currentRoom} = useContext(MeContext);
  const {rwsRef} = useContext(WebSocketContext);

  const {
    classname_icons,
  } = useChatMessage({me, chatLog})

  return (
    <ul className={classname_icons}>
      <li><i
        className={styles.icon}
        onClick={(e)=>{
          // @TODO
          setGood(prevState => !prevState)
          rwsRef?.current?.send(JSON.stringify({
            event_type: EVENT_WEBSOCKET_GOOD_TO_MESSAGE,
            time: new Date(),
            user: me,
            currentRoom: currentRoom,
            chatLog: chatLog,
          } as TYPE_WEBSOCKET_GOOD_TO_MESSAGE))
        }}
      ><img src={
        good || (chatLog.good_length !== undefined && chatLog.good_length >= 1)
          ? "/img/icn_good.svg"
          : "/img/icn_good_off.svg"
      } alt=""/></i>
        <span>{chatLog.good_length ?? 0}</span>
        </li>

    </ul>
  );
}
