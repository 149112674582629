import React from "react";

import styles from "../Entrance.module.css";

export const EntranceWrap: React.FC = ({children}) => {
  return (
    <div className={styles.entrance}>
      <div className={styles.entrance_inner}>
        <EntranceLogo />
        <div className={styles.form}>
          {children}
        </div>
      </div>
    </div>
  )
}

export const EntranceLogo: React.FC = () => {
  return (
    <p className={styles.logo_area}>
      <img className={styles.logo} src="/img/logo.svg" alt="" />
      <br />
      <img className={styles.logo_txt} src="/img/logo_txt.svg" alt="SOLE" />
    </p>
  )
}
