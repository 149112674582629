import * as React from "react";
import {useCallback, useContext} from "react";

import {ModalContext} from "../context/ModalProvider";

export interface useModalItemProps {
  modalStates: any,
  chkModal: Function,
  toggleModal: Function,
  // openModalAttachment: boolean,
  // toggleModalAttachment: Function
  // attachmentData: string,
  // setAttachmentData: Function,
  // previewTag?: React.ReactNode

}

export const useModal = (): useModalItemProps => {

  const {
    modalStates, setModalStates,
    // openModalAttachment, setOpenModalAttachment,
    // attachmentData, setAttachmentData
  } = useContext(ModalContext);

  // Modalの状態チェック
  const chkModal = React.useCallback((key:string) :boolean => {
    if(modalStates[key] === undefined || modalStates[key] === null){
      return false;
    }
    return modalStates[key]
  },[modalStates])

  // Modalの開閉
  const toggleModal = useCallback((key)=>{
    setModalStates(( prevState:any) => {
      let update:boolean;
      if(prevState[key] === undefined || prevState[key] === undefined){
        update = true
      }else{
        update = !prevState[key]
      }
      return {
        ...prevState,
        [key]: update
      }
    })
  },[setModalStates])


  // // 添付確認用Modalの開閉
  // const toggleModalAttachment = useCallback(()=>{
  //   setOpenModalAttachment(( prevState:boolean) => !prevState)
  // },[setOpenModalAttachment])


  // const previewTag = useMemo(()=>{
  //
  //   if(attachmentData.match(/.(jpg|webp|png|gif)$/)){
  //     return (<img src={attachmentData} alt="" />)
  //   }
  //   return (
  //     <video src={attachmentData} width="500" height="300" controls />
  //   )
  //
  // },[attachmentData])

  return {
    chkModal,
    modalStates,
    toggleModal,
    // openModalAttachment,
    // toggleModalAttachment,
    // attachmentData,
    // setAttachmentData,
    // previewTag
  }

}

