import React from 'react'

import {useModal} from "../../../services/useModal";

import styles from "./Modal.module.css"

export interface Props {
  modal_key: string
}

export const ModalWrapper: React.FC<Props> = ({children, modal_key}) => {

  const { chkModal, toggleModal } = useModal();

  if(chkModal(modal_key) === false){
    return (<></>)
  }

  return (
    <div className={styles.modal_wrap}>
      <div className={styles.overlay} onClick={()=>{toggleModal(modal_key)}}>&nbsp;</div>
      <div className={styles.modal}>
        <i className={styles.close_button} onClick={()=>{toggleModal(modal_key)}}>&nbsp;</i>
        {children}
      </div>
    </div>

  )
}
