import React, {useContext, useMemo} from 'react';

import {PageContext} from "../../../../context/pageProvider";
import {TChatLog} from "../../../../types/TChat";
import {Keywords} from "./Keywords";
import {SearchResult} from "./SearchResult";
import styles from './SearchResult.module.css'
import {IconReload} from "./IconReload";

interface WrapProps {
  chatLog: TChatLog
}

export const SearchResults:React.FC<WrapProps> = ({chatLog}) => {

  const {pages} = useContext(PageContext);

  const maxItemLength = useMemo(()=> {
    if( chatLog.chatlog_type === "PAGE"){
      // return 6;
      return 3;
    }
    return 3;
  },[chatLog])


  const page_key = useMemo(()=>{
    if(chatLog.current_word !== undefined
        && chatLog.current_word !== null
    ) {
      return chatLog.current_word;
    }

    return null
  },[chatLog])

  const isExist = useMemo(()=>{
    if(page_key === null){
      return false
    }
    if (pages[page_key] !== undefined && pages[page_key].length >= 1) {
      return true
    }
    return false
  },[chatLog, page_key, pages])

  const SearchResultItems = useMemo(()=>{

    if(!isExist || page_key === null){
      return null
    }

    return [...pages[page_key]]
        .filter((item:any,idx)=>{
          if(idx >= maxItemLength){
            return false;
          }
          return true;
        });

  },[isExist, page_key, maxItemLength, pages])


  const SearchResultEles = useMemo(()=>{

    if(SearchResultItems === null){
      return null
    }

    return (<>
      {SearchResultItems.map((page:any, idx:number)=>{
        return (
          <SearchResult
            key={idx}
            title={page.title}
            url={page.url}
            img_url={page.img_url}
            description={page.description}
          />
        )
      }
      )}
    </>)

  },[SearchResultItems])

  return (
    <div className={styles.image_container}>
      <div className={styles.search_result_head}>
        {chatLog.current_word !== undefined
        && chatLog.current_word !== null
        && chatLog.current_word !== ''
          ? (<p style={{position:'absolute'}}>
            <strong>{chatLog.current_word}</strong> の検索結果
              <IconReload chatLog={chatLog} />
          </p>)
          : null
        }
        <Keywords chatLog={chatLog} />
      </div>
      <div className={styles.image_container_inner}>
        {SearchResultEles}
        {/*
          <SearchResult key="1" title={"記事タイトル1"} description={"ああああああ"} />
          <SearchResult key="2" title={"記事タイトル2"} description={"いいいいいい"} />
          <SearchResult key="3" title={"記事タイトル3"} description={"ううううううう"} />
          */}
      </div>
    </div>
  );
}
