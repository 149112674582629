import React, {useContext, useEffect, useMemo, useState} from 'react';
import {BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';

import {WebSocketListener} from "./WebSocketListener";
// import {IUser} from "../types/TChat";
import {API_HOST} from "../config/config";
import {TimelineContext} from "../context/timelineProvider";
import {MeContext} from "../context/MeProvider";

import {EntranceUser} from "./Entrance/EntranceUser";
import {EntranceRoom} from "./Entrance/EntranceRoom";
import {EntranceIcon} from "./Entrance/EntranceIcon";
import {ChatView} from "./Chat/ChatView";

import styles from './App.module.css'


export const App = () => {

  const {setRooms} = useContext(TimelineContext);
  const {currentRoom} = useContext(MeContext);

  useEffect(() => {
    const f = async () => {
      const res_rooms = await fetch(API_HOST+'/v0/me/rooms', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: `Bearer ${cookies.access_token}`,
        },
      });

      if (res_rooms.status === 200) {
        let json = await res_rooms.json()
        // console.log(json);
        setRooms((prevState: any)=>{
          return [
            ...prevState,
            ...json
          ]
        })
      }
    };
    f();
  },[setRooms]);

  return (
    <Router>
      <Switch>
        <Route path="/room/:room_slug" children={<AppInnerRoom/>}/>
        <Route path="/" children={<AppInnerEntrance/>}/>
      </Switch>
    </Router>
  );
};




const AppInnerEntrance = () => {
  return (
    <div className={styles.app_frame}>
      <EntranceRoom />
    </div>
  );
}

const AppInnerRoom = () => {

  const {me, currentRoom, setCurrentRoom} = useContext(MeContext);
  const [loading, setLoading] = useState<boolean>(false);

  // @ts-ignore
  let { room_slug } = useParams();

  useEffect(() => {
    const f = async () => {
      const res_rooms = await fetch(API_HOST+'/master/room/'+room_slug, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: `Bearer ${cookies.access_token}`,
        },
      });

      if (res_rooms.status === 200) {
        let json = await res_rooms.json()
        // console.log(json)
        setLoading(true)
        setCurrentRoom((prevState: any)=> {
          return {
            ...prevState,
            ...json
          }
        })

      }
    };
    f();
  },[room_slug, setCurrentRoom, setLoading]);

  const contentBlock = useMemo(()=>{

    if(loading === false){
      return (<p style={{padding:"40px",textAlign:"center"}}>Loading...<br />切り替わらない場合、再読込みしてください</p>)
    }
    if(me.enter === undefined || !me.enter
      || !currentRoom

    ){
      if(me.name === undefined || me.name === ''){
        return (<EntranceUser />)
      }
      // if(me.icon === undefined || me.icon.r === undefined){
      //   return (<EntranceIcon />)
      // }
      return (<EntranceIcon />)
    }
    return (<ChatView />)
  },[me, loading, currentRoom])

  return (
    <WebSocketListener>
      <div className={styles.app_frame}>
        {contentBlock}
      </div>
    </WebSocketListener>
  );
};
