import * as React from "react";
import {useCallback, useContext} from "react";

import {
  EVENT_WEBSOCKET_UPDATE_WHITEBOARD_NOTES,
  EVENT_WEBSOCKET_UPDATE_WHITEBOARD_TAGS, TYPE_WEBSOCKET_SEND_GOOD, TYPE_WEBSOCKET_SEND_MESSAGE,
  TYPE_WEBSOCKET_UPDATE_WHITEBOARD_ITEMS
} from "../types/TWebSocket";
import {MeContext} from "../context/MeProvider";
import {WebSocketContext} from "../context/WebSocketProvider";

export interface useWebSocketProps {
  WSSyncWhiteboardTags: Function,
  WSSyncWhiteboardNotes: Function,
  WSSendMessage: Function,
  WSSendGood: Function,
}

export const useWebSocket = (): useWebSocketProps => {

    // const { clientKey } = useContext(WebSocketContext);
    const {rwsRef} = useContext(WebSocketContext);
    const {me, currentRoom} = useContext(MeContext);

    // const {timeline, setTimeline} = useContext(TimelineContext);
    // const {setPages} = useContext(PageContext);
    // const {setTmpRoomName} = useContext(TimelineContext);
    // const {roomUsers, setRoomUsers} = useContext(RoomContext);
    // const {addIdeaTags,
    //     tagIdeas, setTagIdeas,
    //     stickyNotes, setStickyNotes
    // } = useContext(WhiteboardContext);

    // /**
    //  * ルームメンバんー情報を更新
    //  */
    // const updateRoomMember = useCallback((payload:any)=>{
    //     console.log('updateRoomMember');
    //
    //     const _room_slug = payload.currentRoom.slug
    //
    //     let _isSameMember = false
    //     // @ts-ignore
    //     if(roomUsers[_room_slug] !== undefined){
    //         // @ts-ignore
    //         roomUsers[_room_slug].forEach((user:any,idx:number) => {
    //             if (user.name === payload.user.name){
    //                 _isSameMember = true
    //             }
    //         })
    //     }
    //
    //     if(!_isSameMember ){
    //         setRoomUsers((prevState:any)=>{
    //             const prev_users = prevState[_room_slug]
    //             if(prev_users === undefined){
    //                 return {
    //                     ...prevState,
    //                     [_room_slug] : [payload.user]
    //                 }
    //             }
    //             const except_users = prev_users.filter((user:any,idx:number) => {
    //                 return (user.name !== payload.user.name)
    //             })
    //
    //             return {
    //                 ...prevState,
    //                 [_room_slug] : [...prev_users, payload.user]
    //             }
    //         })
    //     }
    //
    // },[roomUsers])
    //
    //
    // // メッセージ受け取り時のイベント
    // const eventRecieveNewMessage = useCallback((payload:any)=>{
    //     console.log('eventRecieveNewMessage');
    //     if(timeline[currentRoom.slug] === undefined){
    //         return
    //     }
    //
    //     let _isSameTimeline = false
    //     timeline[currentRoom.slug].forEach((chatlog:any,idx:number) => {
    //         if (chatlog.hash === payload.hash){
    //             _isSameTimeline = true
    //         }
    //     })
    //     if(!_isSameTimeline){
    //         setTimeline((prevState:any)=>{
    //             return {
    //                 ...prevState,
    //                 [currentRoom.slug]: [
    //                     ...prevState[currentRoom.slug],
    //                     {
    //                         chatlog_type: 'MESSAGE',
    //                         hash: payload.hash,
    //                         user: payload.user,
    //                         body: payload.message,
    //                         date: new Date(payload.date_str),
    //                         good_length: 0,
    //                         words: payload.words,
    //                         current_word: payload.current_word,
    //                     }
    //                 ]
    //             };
    //         });
    //
    //         addIdeaTags(payload.words)
    //
    //         console.log('PLAY')
    //         console.log(sound_receive)
    //         sound_receive.play();
    //         console.log("PLAYED")
    //
    //         updateRoomMember(payload)
    //
    //     }
    //
    //
    // },[timeline,currentRoom, sound_receive, addIdeaTags, updateRoomMember])
    //
    //
    //
    // // 入室時のイベント
    // const eventEnterRoom = useCallback((payload:any)=>{
    //     console.log('eventEnterRoom');
    //
    //     if(currentRoom === null){
    //
    //     }else{
    //
    //         setTimeline((prevState:any)=>{
    //             console.log(currentRoom.slug)
    //             if(prevState[currentRoom.slug] === undefined){
    //                 return prevState
    //             }
    //             return {
    //                 ...prevState,
    //                 [currentRoom.slug]: [
    //                     ...prevState[currentRoom.slug],
    //                     {
    //                         chatlog_type: "BOT",
    //                         body: payload.user.name + ' さんが参加しました。',
    //                         user: payload.user,
    //                         date: new Date(payload.date_str),
    //                     }
    //                 ]
    //             };
    //         });
    //         updateRoomMember(payload)
    //
    //
    //     }
    // },[currentRoom])
    //
    // // Create or reconnect WebSocket
    // const connectWebSocket = useCallback(() => {
    //     console.log(2)
    //     console.log(clientKey)
    //
    //     if(clientKey === ''){
    //         return;
    //     }
    //     const wsInstance = new WebSocket(`${WEBSOCKET_BASE_URL}/${clientKey}`);
    //
    //     wsInstance.onopen = () => {
    //         console.log('WebSocket opened or reconnected');
    //     };
    //     wsInstance.onclose = () => {
    //         console.log('WebSocket closed');
    //     };
    //     wsInstance.onmessage = (event)=>{
    //         // console.log(event);
    //
    //         const j = JSON.parse(event.data)
    //
    //         if (j.event_type === 'PUB_HEALTHCHECK') {
    //             console.log('PUB_HEALTHCHECK')
    //
    //         } else if (j.event_type === 'CHAT_UPDATE_ROOM') {
    //             console.log('CHAT_UPDATE_ROOM LISTNER')
    //
    //             console.log(j.title);
    //             console.log(j.description);
    //             console.log('CHAT_UPDATE_ROOM LISTNER')
    //             setTmpRoomName(j.title)
    //             setCurrentRoom((prevState:any)=>{
    //                 return {
    //                     ...prevState,
    //                     title: j.title ?? '',
    //                     description: j.description ?? ''
    //                 }
    //             })
    //
    //             const room_slug = j.currentRoom.slug
    //
    //             let isSameMember = false
    //             // @ts-ignore
    //             if(room_slug !== undefined && roomUsers[room_slug] !== undefined){
    //                 // @ts-ignore
    //                 roomUsers[room_slug].forEach((user:any,idx:number) => {
    //                     if (user.name === j.user.name){
    //                         isSameMember = true
    //                     }
    //                 })
    //             }
    //
    //             if(!isSameMember ){
    //                 setRoomUsers((prevState:any)=>{
    //                     const prev_users = prevState[room_slug]
    //                     if(prev_users === undefined){
    //                         return {
    //                             ...prevState,
    //                             [room_slug] : [j.user]
    //                         }
    //                     }
    //                     return {
    //                         ...prevState,
    //                         [room_slug] : [...prev_users, j.user]
    //                     }
    //                 })
    //             }
    //
    //         } else if (j.event_type === "CHAT_UPDATE_PAGES") {
    //             console.log('CHAT_UPDATE_PAGES')
    //
    //             // console.log(j.word)
    //             // console.log(j.pages)
    //             const result_items = [...j.pages]
    //                 .sort((a,b) => { return Math.random() - .5; })
    //             ;
    //
    //             setPages((prevState:any)=>{
    //                 return {
    //                     ...prevState,
    //                     [j.word]: result_items
    //                 };
    //             });
    //
    //
    //         }else if (j.event_type === "CHAT_INIT") {
    //             console.log('CHAT_INIT')
    //             // WSSyncWhiteboardNotes(j.items)
    //             // ws_send(JSON.stringify({
    //             //     event_type: EVENT_WEBSOCKET_SYNC_TIMELINE,
    //             //     time: new Date(),
    //             //     user: me,
    //             //     currentRoom: currentRoom,
    //             //     timelines: timeline[currentRoom.slug],
    //             //     ideatags: tagIdeas,
    //             //     stickynotes: stickyNotes,
    //             // } as EVENT_WEBSOCKET_SYNC_TIMELINE))
    //         } else if (j.event_type === "PUB_SYNC_TIMELINE") {
    //             console.log('PUB_SYNC_TIMELINE')
    //             // WSSyncWhiteboardNotes(j.items)
    //             // setTimeline((prevState:any)=>{
    //             //   // 重複は削除
    //             //   const mergedMap= new Map<number, any>([
    //             //       ...prevState[currentRoom.slug],
    //             //     ...j.timelines
    //             //   ].map(i => [i.id, i]));
    //             //   return {
    //             //     ...prevState,
    //             //     [currentRoom.slug] : Array.from(mergedMap.values()).sort((a, b) => a.hash - b.hash)
    //             //   }
    //             // })
    //
    //             // setTagIdeas((prevState:any[])=>{
    //             //   return Array.from(new Set([...prevState, ...j.ideatags]))
    //             // })
    //             //
    //             // setStickyNotes((prevState:TSourceStickyNote[])=>{
    //             //   const mergedMap = new Map<number, TSourceStickyNote>([...prevState, ...j.stickynotes].map(i => [i.id, i]));
    //             //   return Array.from(mergedMap.values()).sort((a, b) => a.id - b.id);
    //             // })
    //
    //         }
    //
    //
    //         if(j.type === "ROOM"){
    //
    //         }else if(j.type === "PENDING"){
    //             // setWips((prevState)=>{
    //             //   return [
    //             //     {
    //             //       name: j.id,
    //             //       body: j.body,
    //             //       date: new Date(j.t),
    //             //     }
    //             //   ];
    //             // });
    //         } else if(j.type === "CLEAR"){
    //             // setWips((prevState)=>{
    //             //   return [];
    //             // });
    //         } else if(j.type === "PAGES"){
    //
    //
    //         } else if(j.type === "MESSAGE"){
    //
    //         }
    //     };
    // }, [
    //
    // ]);
    //
    //
    // // useEffect(() => {
    // //     connectWebSocket();
    // //
    // //     // clean up on unmount
    // //     return () => {
    // //         ws?.close();
    // //     };
    // // }, [connectWebSocket]);

  const WSSendMessage = useCallback((obj:TYPE_WEBSOCKET_SEND_MESSAGE)=>{
    rwsRef?.current?.send(JSON.stringify(obj))

  },[rwsRef])

  const WSSendGood = useCallback((obj:TYPE_WEBSOCKET_SEND_GOOD)=>{
    rwsRef?.current?.send(JSON.stringify(obj))

  },[rwsRef])

  const WSSyncWhiteboardTags = React.useCallback((items:any[])=>{
    rwsRef?.current?.send(JSON.stringify({
      event_type: EVENT_WEBSOCKET_UPDATE_WHITEBOARD_TAGS,
      time: new Date(),
      user: me,
      currentRoom: currentRoom,
      items: items,
    } as TYPE_WEBSOCKET_UPDATE_WHITEBOARD_ITEMS))

  },[rwsRef, me, currentRoom])


  const WSSyncWhiteboardNotes = React.useCallback((items:any[])=>{

    rwsRef?.current?.send(JSON.stringify({
      event_type: EVENT_WEBSOCKET_UPDATE_WHITEBOARD_NOTES,
      time: new Date(),
      user: me,
      currentRoom: currentRoom,
      items: items,
    } as TYPE_WEBSOCKET_UPDATE_WHITEBOARD_ITEMS))

  },[rwsRef, me, currentRoom])

  return {
    WSSyncWhiteboardTags,
    WSSyncWhiteboardNotes,
    WSSendMessage,
    WSSendGood
  }
}
