// import React from 'react';
import {CanvasObject} from "./Object";
import {TMousePosition} from "../Types/TMousePosition";


export class Tag extends CanvasObject {

  public width: number;
  public height: number;
  public x: number;
  public y: number;
  private offset_x: number;
  private offset_y: number;
  private radius: number;


  constructor(
    public text:string = "メモ",
    public idx:number = 0
  ) {
    super();
    this.width = 140
    this.height = 22
    this.offset_x = 10
    this.offset_y = 50
    this.x = 0
    this.y = 10 + (this.idx * (this.height+10))
    this.radius = this.height/2
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.restore(); // 描画状態を復元

    const primary_color = '#F63C57'

    ctx.fillStyle = primary_color;  // 文字色の変更
    ctx.strokeStyle = primary_color;  // ボーダー色の変更
    ctx.lineWidth = 1;  // ボーダー幅の設定

    // 角丸の描画
    ctx.beginPath();
    ctx.moveTo(this.offset_x + this.x + this.radius, this.offset_y + this.y);
    ctx.arcTo(this.offset_x + this.x + this.width, this.offset_y + this.y, this.offset_x + this.x + this.width, this.offset_y + this.y + this.height, this.radius);
    ctx.arcTo(this.offset_x + this.x + this.width, this.offset_y + this.y + this.height, this.offset_x + this.x, this.offset_y + this.y + this.height, this.radius);
    ctx.arcTo(this.offset_x + this.x, this.offset_y + this.y + this.height, this.offset_x + this.x, this.offset_y + this.y, this.radius);
    ctx.arcTo(this.offset_x + this.x, this.offset_y + this.y, this.offset_x + this.x + this.width, this.offset_y + this.y, this.radius);
    ctx.closePath();

    ctx.fillStyle = "#FFFFFF";  // 塗りつぶし色を白色に変更
    ctx.fill();  // 塗りつぶし
    ctx.stroke();  // ボーダーの描画

    // 文字列の描画
    ctx.font = "14px Noto Sans JP";  // 文字列のスタイルを設定（フォントサイズやフォントファミリーなど）
    ctx.fillStyle = primary_color;  // 文字列の色を変更
    const textWidth = ctx.measureText(this.text).width;
    const textX = this.offset_x + this.x + (this.width - textWidth) / 2;  // 文字列を中央に配置するためのX座標を計算
    const textY = this.offset_y + this.y + (this.height / 2) + 5;  // 文字列を中央に配置するためのY座標を計算（微調整）
    ctx.fillText(this.text, textX, textY);  // 文字列を描画
    ctx.restore(); // 描画状態を復元

  }

  moveAndDraw(ctx: CanvasRenderingContext2D, diffX:number, diffY:number){
    this.x = diffX
    this.y = diffY
    this.draw(ctx);
  }

  contains(pos: TMousePosition) {
    return (
        pos.x > this.offset_x + this.x &&
        pos.x < this.offset_x + this.x + this.width &&
        pos.y > this.offset_y + this.y &&
        pos.y < this.offset_y + this.y + this.height
    )
  }
}
