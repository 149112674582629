import React, {useContext, useMemo, useState} from "react";
import { useHistory } from "react-router-dom";

import {API_HOST} from "../../config/config";
import {TimelineContext} from "../../context/timelineProvider";
import {MeContext} from "../../context/MeProvider";

import {EntranceWrap} from "./Components/EntranceParts";


export const EntranceRoom: React.FC = () => {

  const history = useHistory();

  const {setRooms, tmpRoomName, setTmpRoomName} = useContext(TimelineContext);
  const {setCurrentRoom} = useContext(MeContext);
  // ルーム作成エラー
  const [roomError, setRoomError] = useState<boolean>(false)

  const roomErrorMessage = useMemo(()=>{
    if(!roomError){
      return null
    }
    return (<p style={{
      position:"absolute",
      display:"inline-block",
      width:"100%",
      fontSize:"13px",
      top: -15,
      left: "50%",
      transform: "translateX(-50%)",
      color: "#CC0000"
    }}>
      ルーム [<strong>{tmpRoomName}</strong>] は、すでに利用されています。
    </p>)
  },[roomError, tmpRoomName])

  return (
    <EntranceWrap>
      {roomErrorMessage}
      <input type="text"
         value={tmpRoomName}
         placeholder={'ルーム名を入力してください　　※半角英数字'}
         autoComplete="off"
         style={{imeMode:'inactive'}}
         onChange={(event) => {
           setRoomError(false)
           setTmpRoomName(event.target.value)
           setCurrentRoom((prevState:any)=>{
             return {
               ...prevState,
               title: event.target.value,
               description: ''
             }
           })
         }}
      />

      <button
        onClick={(event) => {
          if(tmpRoomName !== ''){

            const f = async () => {
              const obj = {room_slug: tmpRoomName};
              const body = JSON.stringify(obj);
              const res_rooms = await fetch(API_HOST+'/master/room', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                  //   Authorization: `Bearer ${cookies.access_token}`,
                },
                body
              });

              if (res_rooms.status === 200) {
                let json = await res_rooms.json()
                // console.log(json);
                if(json.type === "ERROR"){
                  // console.log('ERROR1');
                  setRoomError(true)
                } else {
                  setRoomError(false)
                  setTmpRoomName(tmpRoomName)
                  setCurrentRoom((prevState: any)=>{
                    return json
                  })
                  setRooms((prevState:any)=>{
                    return [
                      ...prevState,
                      {
                        ...json
                      }
                    ]
                  })
                  history.push('/room/' + tmpRoomName)
                }
              }else{
                console.log('ERROR2');
              }
            };
            f();

          }
        }}
        disabled={tmpRoomName === ''}
      >NEXT</button>
    </EntranceWrap>
  )
}
