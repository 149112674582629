import React, {useContext, useMemo} from 'react';

import {IUser} from "../../types/TChat";

import {RoomFrame} from "./Components/RoomFrame";

import styles from './ChatView.module.css'

import {ChatContextMenu} from "./Components/ChatContextMenu";
import {MeContext} from "../../context/MeProvider";


export const ChatView:React.FC = () => {
  const {currentRoom} = useContext(MeContext);

  const room_style = useMemo(()=>{
    if(currentRoom === undefined || currentRoom === null){
      return styles.mainarea
    }
    return styles.mainarea_active
  },[currentRoom])

  return (<div className={room_style}>
    <ChatContextMenu>
      <RoomFrame />
    </ChatContextMenu>
  </div>)
};


export const getProfileBgStyle = (user:IUser)=>{
  if(user.icon === undefined || user.icon.r === undefined){
    return {
      backgroundColor:'rgba(100,100,100,1)'
    }
  }
  return {
    backgroundColor:'rgba(' + user.icon.r + ',' + user.icon.g + ',' + user.icon.b + ',1)'
  }
}
