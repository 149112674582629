import React, {useCallback, useContext, useMemo} from 'react';

import {TChatLog} from "../../../../types/TChat";
import {API_HOST} from "../../../../config/config";

import {PageContext} from "../../../../context/pageProvider";
import {TimelineContext} from "../../../../context/timelineProvider";
import {MeContext} from "../../../../context/MeProvider";

import styles from './IconReload.module.css'

interface Props {
  chatLog: TChatLog
}


export const IconReload:React.FC<Props> = ({chatLog}) => {

  // const current_word = '111';
  const {pages,setPages} = useContext(PageContext);
  const {setTimeline} = useContext(TimelineContext);
  const {me, currentRoom} = useContext(MeContext);

  const seeKeywords = useMemo(()=>{
    if(chatLog.words === undefined){
      return null;
    }
    if(chatLog.words.length >= 2){
      return (<p className={styles.seeKeywords}>他
        <strong>
          {chatLog.words.length - 1}キーワード
        </strong>
        を見る
        <img className={styles.icn_arrow_more} src="/img/icn_arrow_more.svg" alt=""/>
      </p>)
    }
  },[chatLog.words]);


  // キーワードを切り替え
  const handleClick = useCallback((hash:any, word:string)=>{

    const f = async () => {
      const obj = {
        user: me,
        word:word
      };
      const body = JSON.stringify(obj);

      const res_keyword = await fetch(API_HOST+'/v0/page_search/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: `Bearer ${cookies.access_token}`,
        },
        body
      });

      if (res_keyword.status === 200) {
        let json = await res_keyword.json()
        // console.log(json);

        if( json !== null){

          const result_items = [...json]
              .sort((a,b) => { return Math.random() - .5; })
          ;

          setPages((prevState:any)=>{
            return {
              ...prevState,
              [word]: result_items ?? []
            };
          });
        }

      }
    };
    f();

  },[pages, setPages, setTimeline,currentRoom.slug,me])

  if(chatLog.current_word === undefined
  || chatLog.current_word === ''){
    return null
  }
  return (
      <img src="/img/icn_reload.svg" alt=""
           className={styles.icn_reload}
           onClick={(e)=>{
             e.preventDefault();
             handleClick(chatLog.hash, chatLog.current_word ??'')
           }}
      />
  );
}
