import React, { useContext, useState} from "react";

import {IUser} from "../../types/TChat";
import {MeContext} from "../../context/MeProvider";

import {EntranceWrap} from "./Components/EntranceParts";
// import {useParams} from "react-router-dom";


export const EntranceUser: React.FC = () => {

  // @ts-ignore
  // let { room_slug } = useParams();

  const {setMe} = useContext(MeContext);
  //仮ユーザ名
  const [tmpname, setTmpName] = useState<string>('')

  return (
    <EntranceWrap>
      <input type="text"
        // defaultValue={name}
             value={tmpname}
             placeholder={'お名前を入力してください'}
             autoComplete="off"
             onChange={(event) => {
               setTmpName(event.target.value)
             }}
      />
      <button
        onClick={(event) => {
          if(tmpname !== ''){

            setMe((prevState:IUser)=>{
              return {
                ...prevState,
                name: tmpname,
                user_id: tmpname,
                // user_id: json.id,
                lang: 'ja'
              }
            })

            // const f = async () => {
            //   const obj = {
            //     room_slug: room_slug,
            //     user_name:tmpname
            //   };
            //   const body = JSON.stringify(obj);
            //   const res = await fetch(API_HOST+'/v0/user/enter', {
            //     method: 'POST',
            //     headers: {
            //       'Accept': 'application/json',
            //       'Content-Type': 'application/json'
            //       //   Authorization: `Bearer ${cookies.access_token}`,
            //     },
            //     body
            //   });
            //
            //   if (res.status === 200) {
            //     let json = await res.json()
            //     // console.log(json);
            //     if(json.type !== undefined && json.type === "ERROR"){
            //       // console.log('ERROR1');
            //       // setRoomError(true)
            //     } else {
            //       setMe((prevState:IUser)=>{
            //         return {
            //           ...prevState,
            //           name: tmpname,
            //           user_id: json.id,
            //           lang: 'ja'
            //         }
            //       })
            //     }
            //   }else{
            //     console.log('ERROR2');
            //   }
            // };
            // f();

          }
        }}
        disabled={tmpname === ''}
      >NEXT</button>
    </EntranceWrap>
  )
}
