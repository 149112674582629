import { useMemo} from "react";

import {IUser, TChatLog} from "../types/TChat";
import styles from "../views/Chat/Components/Message/Message.module.css";
import profile_styles from "../views/Chat/Components/Message/ProfileIcon.module.css";
import good_styles from "../views/Chat/Components/Message/Good.module.css";


interface Props {
  me: IUser,
  chatLog: TChatLog
}

export const useChatMessage = ({me, chatLog}:Props) => {

  const isMine = useMemo(()=>{
    return me.name === chatLog.user.name
  },[me, chatLog])

  const classname_chat_detail = useMemo(()=>{
    return isMine ? styles.my_chat_detail: styles.chat_detail
  },[isMine])

  const classname_meta = useMemo(()=>{
    return isMine ? profile_styles.my_meta: profile_styles.meta
  },[isMine])

  const classname_profile_icon = useMemo(()=>{
    return isMine ? profile_styles.my_profile_icon: profile_styles.profile_icon
  },[isMine])

  const classname_icons = useMemo(()=>{
    return isMine ? good_styles.my_icons: good_styles.icons
  },[isMine])

  const classname_name = useMemo(()=>{
    return isMine ? profile_styles.my_name: profile_styles.name
  },[isMine])


  return {
    isMine,
    classname_chat_detail,
    classname_meta,
    classname_profile_icon,
    classname_icons,
    classname_name,
  };
}