import React, {useContext} from 'react';

import {Item, Menu, Separator, useContextMenu} from "react-contexify";
// import {
//   Submenu,
//   theme as builtInTheme,
//   animation as builtInAnimation,
//   ItemParams,
// } from "react-contexify";
import 'react-contexify/dist/ReactContexify.css';
import {MeContext} from "../../../context/MeProvider";
import {TimelineContext} from "../../../context/timelineProvider";
import {API_HOST} from "../../../config/config";
import {PageContext} from "../../../context/pageProvider";


export const ChatContextMenu:React.FC = ({children}) => {

  const {pages,setPages} = useContext(PageContext);
  const {me, currentRoom, contextMenuText, contextMenuChatLog} = useContext(MeContext);
  const {setTimeline} = useContext(TimelineContext);

  const CONTEXTMENU_ID_CHAT = 'CHAT_CONTEXTMENU';


  function handleItemClick({ event, props, triggerEvent, data }:any){
    console.log(event, props, triggerEvent, data );
  }

  function handleWordSearch({ event, props, triggerEvent, data }:any){

    console.log(event, props, triggerEvent, data );

    const word = contextMenuText

    setTimeline((prevState:any)=>{
      return {
        ...prevState,
        [currentRoom.slug]: prevState[currentRoom.slug].map((item:any,idx:number)=>{
          if(item.hash === undefined || contextMenuChatLog.hash === undefined){
            return item
          }
          if(item.hash === contextMenuChatLog.hash){

            const filtered_words = item.words.filter((w:string,idx:number)=>{
              return w !== word
            })
            return {
              ...item,
              current_word: word,
              words:[
                word,
                ...filtered_words
              ]
            }
          }
          return item

        })
      };
    });

    if(pages[word] === undefined || pages[word].length === 0){
      const f = async () => {

        const obj = {
          user: me,
          word: word
        };
        const body = JSON.stringify(obj);

        const res_keyword = await fetch(API_HOST+'/v0/page_search/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            //   Authorization: `Bearer ${cookies.access_token}`,
          },
          body
        });

        if (res_keyword.status === 200) {
          let json = await res_keyword.json()
          console.log(json);

          if( json !== null){

            const result_items = [...json]
              .sort((a,b) => { return Math.random() - .5; })
            ;

            setPages((prevState:any)=>{
              return {
                ...prevState,
                [word]: result_items ?? []
              };
            });
          }

        }
      };
      f();
    }

  }

  return (<>

    {children}
    {/*<ChatContextTriggerSample />*/}

    <Menu id={CONTEXTMENU_ID_CHAT}>
      {
        contextMenuText === ''
          ? null
          : (<Item onClick={handleWordSearch}>
            <strong>「{contextMenuText}」</strong>を検索
          </Item>)
      }
      <Item onClick={handleItemClick}>
        返信
      </Item>
      <Separator />
      <Item onClick={handleItemClick}>
        通報
      </Item>
    </Menu>
  </>)
};


export const ChatContextTriggerSample:React.FC = ({children}) => {

  const {setContextMenuText} = useContext(MeContext);

  const CONTEXTMENU_ID_CHAT = 'CHAT_CONTEXTMENU';
  const { show } = useContextMenu({
    id: CONTEXTMENU_ID_CHAT,
  });


  function displayMenu(e:any){
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    const selection = window.getSelection();
    if(selection !== undefined && selection !== null){
      console.log(selection.toString());
      setContextMenuText(selection.toString());
    }else{
      setContextMenuText('');
    }
    show(e);
  }

  return (<>
    <div onContextMenu={displayMenu}>
      アイウエオ<br />アイウエオ<br />鬼滅の刃<br />アイウエオ<br />アイウエオ<br />アイウエオ<br />アイウエオ<br />アイウエオ<br />アイウエオ<br />アイウエオ<br />アイウエオ<br />
    </div>
  </>)
};

