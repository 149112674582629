import {CanvasObject} from "./Object";
import {TMousePosition} from "../Types/TMousePosition";


export class StickyNote extends CanvasObject {

  private primary_color: string;
  private shadow_color: string;
  private shadow_blur: number;
  private shadow_position: any;

  constructor(
    public x: number,
    public y: number,
    public width: number,
    public height: number,
    public fillStyle = "#000",
    public text = "メモ"
  ) {
    super();
    this.primary_color = '#E3E683'
    this.shadow_color = 'rgba(0, 0, 0, 0.16)';
    this.shadow_blur = 6
    this.shadow_position = {
      x: 0,
      y: 3
    }

  }

  draw(ctx: CanvasRenderingContext2D) {

    ctx.save();  // 描画状態を保存

    const foldSize = 10

    ctx.shadowColor = 'rgba(0, 0, 0, 0.15)';  // 影の色
    ctx.shadowBlur = 10;  // ぼかしの大きさ
    ctx.shadowOffsetX = 0;  // 影のX方向のずれ
    ctx.shadowOffsetY = 3;  // 影のY方向のずれ


    ctx.fillStyle = this.primary_color;
    // ctx.fillRect(this.x, this.y, this.width, this.height);
    // まず本来の付箋部分を描画
    ctx.beginPath();
    ctx.moveTo(this.x, this.y);
    ctx.lineTo(this.x + this.width, this.y);
    ctx.lineTo(this.x + this.width, this.y + this.height - foldSize);

    // ctx.lineTo(this.x + this.width, this.y + this.height);

    ctx.lineTo(this.x + this.width - foldSize, this.y + this.height);
    ctx.lineTo(this.x, this.y + this.height);
    ctx.closePath();
    ctx.fill();

    ctx.restore(); // 描画状態を復元

    // // 最後に折り曲げ部分を描画
    // ctx.fillStyle = '#E3E683';
    // ctx.beginPath();
    // ctx.moveTo(this.x + this.width - foldSize, this.y + this.height);
    // ctx.lineTo(this.x + this.width - foldSize, this.y + this.height - foldSize);
    // ctx.lineTo(this.x + this.width, this.y + this.height - foldSize);
    // ctx.closePath();
    // ctx.fill();



    // 文字列を描画
    ctx.font = "14px Noto Sans JP"; // 文字列のスタイルを設定（フォントサイズやフォントファミリーなど）
    ctx.fillStyle = "#333333"; // 文字列の色を設定


    let wrappedText = wrapText(ctx,
        this.text, this.x+10, this.y, this.width-20, this.height-20, 22);
// wrappedTe
    wrappedText.forEach(function(item) {
      // item[0] is the text
      // item[1] is the x coordinate to fill the text at
      // item[2] is the y coordinate to fill the text at
      ctx.fillText(item.text, item.x, item.y);
    })

    // const textWidth = ctx.measureText(this.text).width;
    // const textX = this.x + (this.width - textWidth) / 2; // 文字列を中央に配置するためのX座標を計算
    // const textY = this.y + this.height / 2; // 文字列を中央に配置するためのY座標を計算
    // ctx.fillText(this.text, textX, textY); // 文字列を描画
    ctx.restore(); // 描画状態を復元

  }

  contains(pos: TMousePosition) {
    return (
      pos.x > this.x &&
      pos.x < this.x + this.width &&
      pos.y > this.y &&
      pos.y < this.y + this.height
    );
  }
}


interface TWrapTextResultProps {
  text:string,
  x:number,
  y:number,
}

// @description: wrapText wraps HTML canvas text onto a canvas of fixed width
const wrapText = function(ctx:CanvasRenderingContext2D, text:string, x:number, y:number, maxWidth:number, maxHeight:number, lineHeight:number) :TWrapTextResultProps[] {
  // 1行ずらす
  y += lineHeight;

  let words = Array.from(text);
  let line = '';
  let testLine = '';
  let lineArray = [] as TWrapTextResultProps[]
  let accumulatedHeight = 0; // 追加: 累積されたテキストの高さをトラッキングする

  for(var n = 0; n < words.length; n++) {
    testLine += `${words[n]}`;
    let metrics = ctx.measureText(testLine);
    let testWidth = metrics.width;

    if (testWidth > maxWidth && n > 0) {
      lineArray.push({
        text: line,
        x: x,
        y: y
      });
      y += lineHeight;
      line = `${words[n]}`;
      testLine = `${words[n]}`;
      accumulatedHeight += lineHeight; // 行が追加される度に累積高さを増やす
    } else {
      line += `${words[n]}`;
    }

    if (accumulatedHeight > maxHeight) { // 最大高さを超えたらループを抜ける
      break;
    }

    if(n === words.length - 1) {
      lineArray.push({
        text: line,
        x: x,
        y: y
      });
    }
  }

  return lineArray;
}
