import React, {useContext, useMemo} from 'react';

import {MeContext} from "../../../../context/MeProvider";

import styles from './RoomHeader.module.css'
import {useModal} from "../../../../services/useModal";


export const InviteBox:React.FC = () => {

  const {currentRoom} = useContext(MeContext);
  const { toggleModal } = useModal();

  const room_url = useMemo(()=>{
    return "https://sole.socialis.co.jp/room/"+currentRoom.slug
  },[currentRoom])

  // const invite = useCallback((e)=>{
  //   navigator.clipboard.writeText(room_url)
  //     .then(()=>{
  //       // console.log('copied');
  //       alert('URLコピーしました');
  //     })
  //     .catch((err)=>{
  //       console.log(err);
  //     })
  // },[room_url])

  return (
    <>
      <div className={styles.inviteBox}
           // onClick={invite}
           onClick={(e)=>{
             toggleModal('INVITE')
           }}
      >
        <img className={styles.icn_comment} src="/img/icn_link.svg" alt="" />
        <span>{room_url}</span>
      </div>
      <i className={styles.icn_invite}
         onClick={(e)=>{
           toggleModal('INVITE')
         }}
      ><img src="/img/icn_invite.svg" alt=""/></i>
    </>
  );
}