import React, {useCallback, useContext, useMemo} from 'react'

import {MeContext} from "../../../context/MeProvider";
import {ModalWrapper} from "./ModalWrapper";

import styles from './Modal.module.css'

export const InviteModal: React.FC = () => {

  const {currentRoom} = useContext(MeContext);

  const room_url = useMemo(()=>{
    return "https://sole.socialis.space/room/"+currentRoom.slug
  },[currentRoom])

  const invite = useCallback((e)=>{
    navigator.clipboard.writeText(room_url)
      .then(()=>{
        // console.log('copied');
        alert('URLコピーしました');
      })
      .catch((err)=>{
        console.log(err);
      })
  },[room_url])

  return (
    <ModalWrapper modal_key={"INVITE"}>
      <div className={styles.modal_content}>
        <h2>「{currentRoom.title}」 に招待</h2>

        <p style={{textAlign:"center"}}>URLをコピーして、招待者に教えてください。</p>
        <p style={{textAlign:"center"}}>
          <strong
            // ref={urlEle}
          >{room_url}</strong>
        </p>

        <nav className={styles.buttons}>
          <ul>
            <li><span
                   onClick={(e)=>{
                     e.preventDefault()
                     invite(e)
                   }}
            >URLをコピー</span></li>

          </ul>
        </nav>
      </div>
    </ModalWrapper>
  )
}
