import React, {useCallback, useContext, useMemo} from 'react';

import {EVENT_WEBSOCKET_UPDATE_ROOM, TYPE_WEBSOCKET_UPDATE_ROOM} from "../../../../types/TWebSocket";

import {MeContext} from "../../../../context/MeProvider";
import {RoomContext} from "../../../../context/roomProvider";
import {WebSocketContext} from "../../../../context/WebSocketProvider";
import {IUser} from "../../../../types/TChat";
import {useModal} from "../../../../services/useModal";
import {getProfileBgStyle} from "../../ChatView";

import {FunctionMenu} from "./FunctionMenu";
import {InviteBox} from "./InviteBox";
import {ExportModal} from "../ExportModal";
import {InviteModal} from "../InviteModal";


import styles from './RoomHeader.module.css'


export const RoomHeader:React.FC = () => {

  const {me, currentRoom, setCurrentRoom} = useContext(MeContext);
  const {roomUsers} = useContext(RoomContext);
  // const {tmpRoomName, setTmpRoomName} = useContext(TimelineContext);
    const {rwsRef} = useContext(WebSocketContext);


  const userIcons = useMemo(()=>{

    if(roomUsers[currentRoom.slug] === undefined){
      return null;
    }
    return roomUsers[currentRoom.slug].map((user:IUser)=>{
      if(user.icon === undefined){
        return null;
      }
      return (
        <li key={user.icon.id}><i
               className={styles.profile_icon}
               style={getProfileBgStyle(user)}
        ><img src="/img/icn_profile.svg"
              alt={user.name}
              title={user.name}
        /></i></li>
      )
    })
  },[roomUsers, currentRoom])

  return (
    <div className={styles.room_header}>
      <div className={styles.room_header_left}>
        <img className={styles.logo} src="/img/logo.svg" alt="" />

        <div className={styles.room_name_wrap}>
          <div className={styles.room_name}>
            <input type={'text'}
                   value={currentRoom.title ??''}
                   maxLength={50}
                   disabled={true}
                   onChange={(e)=>{
                     // setTmpRoomName(e.currentTarget.value)
                     console.log(e.target.value)
                     setCurrentRoom((prevState:any)=>{
                       return {
                         ...prevState,
                         title: e.target.value
                       }
                     })
                   }}
                   onBlur={(e)=>{
                     // setCurrentRoom((prevState:any)=>{
                     //   return {
                     //     ...prevState,
                     //     title: e.target.value
                     //   }
                     // })

                     rwsRef?.current?.send(JSON.stringify({
                       event_type: EVENT_WEBSOCKET_UPDATE_ROOM,
                       time: new Date(),
                       user: me,
                       currentRoom: currentRoom,
                       title: e.target.value,
                       description: currentRoom.description ?? '',
                     } as TYPE_WEBSOCKET_UPDATE_ROOM))
                   }}
            />
          </div>
          <div className={styles.room_topic}>
            <input type={'text'}
                   value={currentRoom.description ?? ''}
                   placeholder={""}
                   maxLength={50}
                   disabled={true}
                   onChange={(e)=>{
                     // setTmpRoomName(e.currentTarget.value)
                     console.log(e.target.value)
                     setCurrentRoom((prevState:any)=>{
                       return {
                         ...prevState,
                         description: e.target.value ?? ''
                       }
                     })
                   }}
                   onBlur={(e)=>{
                     // setCurrentRoom((prevState:any)=>{
                     //   return {
                     //     ...prevState,
                     //     description: e.target.value
                     //   }
                     // })
                     // console.log(e.target.value);

                     rwsRef?.current?.send(JSON.stringify({
                       event_type: EVENT_WEBSOCKET_UPDATE_ROOM,
                       time: new Date(),
                       user: me,
                       currentRoom: currentRoom,
                       title: currentRoom.title,
                       description: e.target.value ?? '',
                     } as TYPE_WEBSOCKET_UPDATE_ROOM))
                   }}
            />
          </div>
        </div>
        <ul className={styles.room_members}>
          {userIcons}
        </ul>
      </div>

      <RoomHeaderMenus />
      <InviteModal />
      <ExportModal />

    </div>
  );
}


const RoomHeaderMenus:React.FC = () => {

  const {me, setMe} = useContext(MeContext);
  const { toggleModal } = useModal();

  const changeLang = useCallback((lang)=>{
    setMe((prevState:IUser)=>{
      return {
        ...prevState,
        lang: lang
      }
    })
  },[setMe])

  return (
    <div className={styles.menu_block}>
      <InviteBox />

      {/*<div className={styles.icn_bell}><img src="/img/icn_bell.svg" alt=""/></div>*/}
      {/*<div className={styles.icn_help}><img src="/img/icn_help.svg" alt=""/></div>*/}
      <div className={styles.export}
           onClick={(e)=>{
             toggleModal('EXPORT')
           }}>議事録
      </div>
        {/*
      <div>
        <ul className={styles.lang_nav}>
          <li className={me.lang==='ja'? styles.lang_active: ""} onClick={(e)=>{ changeLang('ja')}}>JA</li>
          <li className={me.lang==='en'? styles.lang_active: ""} onClick={(e)=>{ changeLang('en')}}>EN</li>
        </ul>
      </div>
      */}
      <div className={styles.icn_arrow_bottom}>
        <img src="/img/icn_arrow_bottom.svg" alt=""/>
            <FunctionMenu />
      </div>
      {/*<p className={styles.profile_name}>{me.name ?? ''}</p>*/}
    </div>
  )
}
