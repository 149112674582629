import * as React from 'react';
import {useCallback} from "react";

interface RoomProviderProps {
    // timeline: any;
}

type Props = {
    children?: React.ReactNode;
};

type TExtraMode = 'SEARCH' | 'MINDMAP' | 'WHITEBOARD'

interface ContextProps {
    roomUsers: any
    setRoomUsers: (data:any) => void,
    extraMode: TExtraMode,
    setExtraMode:any,
    updateRoomMember:Function
}

// Contextを生成
export const RoomContext = React.createContext<ContextProps>({
    roomUsers: {},
    setRoomUsers: (data) => null,
    extraMode: 'SEARCH',
    setExtraMode: () => null,
    updateRoomMember: (payload:any) => null
});


/**
 * RoomProvider
 */
const RoomProvider: React.FC<RoomProviderProps> = ({ children }:Props) => {
    const [roomUsers, setRoomUsers] = React.useState<any>({});
    const [extraMode, setExtraMode] = React.useState<TExtraMode>('SEARCH');
    // 言語データを更新
    React.useEffect(() => {
        // setRoom(getLocaleData(timeline));
    }, [roomUsers]);

    /**
     * ルームメンバー情報を更新
     */
    const updateRoomMember = useCallback((payload:any)=>{
        console.log('_updateRoomMember');

        const _room_slug = payload.currentRoom.slug

        let _isSameMember = false
        // @ts-ignore
        if(roomUsers[_room_slug] !== undefined){
            // @ts-ignore
            roomUsers[_room_slug].forEach((user:any,idx:number) => {
                if (user.name === payload.user.name){
                    _isSameMember = true
                }
            })
        }

        if(!_isSameMember ){
            setRoomUsers((prevState:any)=>{
                const prev_users = prevState[_room_slug]
                if(prev_users === undefined){
                    return {
                        ...prevState,
                        [_room_slug] : [payload.user]
                    }
                }
                // const except_users = prev_users.filter((user:any,idx:number) => {
                //     return (user.name !== payload.user.name)
                // })

                return {
                    ...prevState,
                    [_room_slug] : [...prev_users, payload.user]
                }
            })
        }

    },[roomUsers, setRoomUsers])

    return (
        <RoomContext.Provider value={{
            roomUsers, setRoomUsers,
            extraMode, setExtraMode,
            updateRoomMember
        }}>
            {children}
        </RoomContext.Provider>
    );
};
export default RoomProvider;