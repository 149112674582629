import React from 'react';

import {TChatLog} from "../../../../types/TChat";

import {MessageBot} from "./MessageBot";
import {MessageText} from "./MessageText";
import {MessagePage} from "./MessagePage";
import {SearchResults} from "../SearchResult/SearchResults";

import styles from "./Message.module.css";
import {MessageGood} from "./MessageGood";
// import {Good} from "./Good";
import {MessageDropWrapOuter} from "./MessageDropWrap";

interface Props {
  chatLog: TChatLog
}

export const Message:React.FC<Props> = ({chatLog}) => {

  let m;
  let has_search = false;
  if(chatLog.chatlog_type === "BOT"){
    m = (<MessageBot chatLog={chatLog} />);
  } else if(chatLog.chatlog_type === "MESSAGE"){
    m = (<MessageText chatLog={chatLog} />);
    has_search = true;
  } else if(chatLog.chatlog_type === "PAGE"){
    m = (<MessagePage chatLog={chatLog} />);
    has_search = true;
  } else if(chatLog.chatlog_type === "GOOD"){
    m = (<MessageGood chatLog={chatLog} />);
  }
  // else if (typeof chatLog.body === "string") {
  //   m = (<MessageText chatLog={chatLog} />);
  // }else if( typeof chatLog.body === "object"){
  //   m = (<MessagePage chatLog={chatLog} />);
  // }

  return (
    <article className={styles.message}>
      {m}
      {has_search
        ? (<SearchResults chatLog={chatLog}/>)
        : null
      }

    </article>)
}

export const MessageBlank:React.FC = () => {

  return (
      <article className={styles.message_blank} style={{
        borderTop:'none',
      }}>
        <MessageDropWrapOuter>
          <div className={styles.my_body_message_box}
          style={{
            minHeight: "100px",
            borderTop:'none',
          }}>
            &nbsp;
          </div>
        </MessageDropWrapOuter>
      </article>)
}


// export const Message2:React.FC<Props> = ({chatLog,pages, user}) => {
//   let m;
//   if (typeof chatLog.body === "string") {
//     m = (<MessageText2
//       pages={pages}
//       chatLog={chatLog}
//       user={user}
//     />);
//   }else if( typeof chatLog.body === "object"){
//     m = (<MessagePage
//       pages={pages}
//       chatLog={chatLog}
//       user={user}
//     />);
//   }
//   return (<>{m}</>);
// }
