import React, {useContext} from 'react';

import styles from './ExtraSwitchButton.module.css'
import {RoomContext} from "../../../../context/roomProvider";

interface Props {
}

export const ExtraSwitchButton:React.FC<Props> = () => {

  const {extraMode, setExtraMode} = useContext(RoomContext);


  return (
    <ul  className={styles.toggle_lists}>
      <li
        onClick={()=>{setExtraMode('SEARCH')}}
        className={extraMode === 'SEARCH' ? styles.active : ''}
      >検索</li>
      <li
        onClick={()=>{setExtraMode('WHITEBOARD')}}
        className={extraMode === 'WHITEBOARD' ? styles.active : ''}
      >ホワイトボード</li>
    </ul>
  );
}
