import * as React from 'react';

interface PageProviderProps {
    // page: any;
}

type Props = {
    children?: React.ReactNode;
};

interface ContextProps {
    pages: any;
    setPages: Function;
}

// Contextを生成
export const PageContext = React.createContext<ContextProps>({
    pages: {},
    setPages: (data:any) => null,
});

/**
 * PageProvider
 */
const PageProvider: React.FC<PageProviderProps> = ({ children }:Props) => {
    const [pages, setPages] = React.useState<any>({});
    // 言語データを更新
    React.useEffect(() => {
        // setPage(getLocaleData(page));
    }, [pages]);

    return (
        <PageContext.Provider value={{
            pages, setPages
        }}>
            {children}
        </PageContext.Provider>
    );
};
export default PageProvider;