import {ICurrentRoom, IUser, TChatLog} from "./TChat";

export const EVENT_WEBSOCKET_HEALTHCHECK = "HEALTHCHECK"
export const EVENT_WEBSOCKET_INIT = "INIT"
export const EVENT_WEBSOCKET_ENTER_ROOM = "ENTER_ROOM"
export const EVENT_WEBSOCKET_UPDATE_ROOM = "UPDATE_ROOM"
export const EVENT_WEBSOCKET_SEND_MESSAGE = "SEND_MESSAGE"
export const EVENT_WEBSOCKET_SHARE_PAGE = "SHARE_PAGE"
export const EVENT_WEBSOCKET_SEND_GOOD = "SEND_GOOD"
export const EVENT_WEBSOCKET_GOOD_TO_MESSAGE = "GOOD_TO_MESSAGE"
export const EVENT_WEBSOCKET_CREAR_MESSAGE = "CREAR_MESSAGE"
export const EVENT_WEBSOCKET_EDITING_MESSAGE = "EDITING_MESSAGE"

export const EVENT_WEBSOCKET_UPDATE_WHITEBOARD_TAGS = "UPDATE_WHITEBOARD_TAGS"
export const EVENT_WEBSOCKET_UPDATE_WHITEBOARD_NOTES = "UPDATE_WHITEBOARD_NOTES"
export const EVENT_WEBSOCKET_SYNC_TIMELINE = "SYNC_TIMELINE"


export type TYPE_WEBSOCKET_EVENT =
  "GOOD_TO_MESSAGE"
  | "SEND_GOOD"
  | "SEND_MESSAGE"
  | "SHARE_PAGE"
  | "UPDATE_ROOM"
  | "CREAR_MESSAGE"
  | "EDITING_MESSAGE"
  | "UPDATE_WHITEBOARD_TAGS"
  | "UPDATE_WHITEBOARD_NOTES"
  | "SYNC_TIMELINE"


export interface TYPE_WEBSOCKET_BASE {
  event_type: string,
  time: Date,
  user: IUser,
  currentRoom: ICurrentRoom,
}

export interface EVENT_WEBSOCKET_HEALTHCHECK extends TYPE_WEBSOCKET_BASE {
  event_type: "HEALTHCHECK",
}

export interface EVENT_WEBSOCKET_SYNC_TIMELINE extends TYPE_WEBSOCKET_BASE {
  "timelines": any[],
  "ideatags": any[],
  "stickynotes": any[],
}


export interface TYPE_WEBSOCKET_SEND_MESSAGE extends TYPE_WEBSOCKET_BASE {
  event_type: "SEND_MESSAGE",
  hash: string,
  message: string,
  words: string[],
  current_word: string
}

export interface TYPE_WEBSOCKET_SEND_GOOD extends TYPE_WEBSOCKET_BASE {
  event_type: "SEND_GOOD",
}

export interface TYPE_WEBSOCKET_GOOD_TO_MESSAGE extends TYPE_WEBSOCKET_BASE {
  event_type: "GOOD_TO_MESSAGE",
  chatLog: TChatLog,
}

export interface TYPE_WEBSOCKET_SHARE_PAGE extends TYPE_WEBSOCKET_BASE {
  event_type: "SHARE_PAGE",
  hash: string,
  page: any,
  words: string[],
  current_word: string
}
export interface TYPE_WEBSOCKET_INIT extends TYPE_WEBSOCKET_BASE {
  event_type: "INIT",
  // title: string,
}
export interface TYPE_WEBSOCKET_ENTER_ROOM extends TYPE_WEBSOCKET_BASE {
  event_type: "ENTER_ROOM",
  // title: string,
}
export interface TYPE_WEBSOCKET_UPDATE_ROOM extends TYPE_WEBSOCKET_BASE {
  event_type: "UPDATE_ROOM",
  title: string,
  description: string,
}
export interface TYPE_WEBSOCKET_UPDATE_WHITEBOARD_ITEMS extends TYPE_WEBSOCKET_BASE {
  event_type: "UPDATE_WHITEBOARD_TAGS" | "UPDATE_WHITEBOARD_NOTES",
  items: any[],

}


export const LISTNER_WEBSOCKET_GOOD = "GOOD"

export type TYPE_WEBSOCKET_LISTNER =
  "GOOD"
  | "MESSAGE"
  | "SHARE"
  | "UPDATE_ROOM"
  | "CREAR"
  | "PENDING"