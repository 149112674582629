import React, {FormEvent, useCallback, useContext, useRef, useState} from 'react';
// import {useDrop} from "react-dnd";

import {WhiteboardContext} from "../../../../context/WhiteboardProvider";
import {TimelineContext} from "../../../../context/timelineProvider";
import {MeContext} from "../../../../context/MeProvider";
import {WebSocketContext} from "../../../../context/WebSocketProvider";

// import {TDropSearchShare} from "../../../../types/TDrop";
// import {SearchResult} from "../SearchResult/SearchResult";

import styles from './ChatForm.module.css'
import {
  EVENT_WEBSOCKET_SEND_GOOD,
  EVENT_WEBSOCKET_SEND_MESSAGE,
  TYPE_WEBSOCKET_SEND_GOOD,
  TYPE_WEBSOCKET_SEND_MESSAGE,
} from "../../../../types/TWebSocket";

import kuromoji from 'kuromoji'
import {sound} from "../../../../index";
import {useWebSocket} from "../../../../services/useWebSocket";

// var TinySegmenter = require('../../../../../node_modules/tiny-segmenter/lib/index');
// const tinySegmenter = new TinySegmenter();


export const ChatForm:React.FC = () => {

  const {rwsRef} = useContext(WebSocketContext);

  const {me, currentRoom} = useContext(MeContext);
  const {setTimeline} = useContext(TimelineContext);
  const {addIdeaTags} = useContext(WhiteboardContext);

  const [message, setMessage] = useState('')
  // const [shareData, setShareData] = useState<TDropSearchShare>({} as TDropSearchShare)
  const {WSSyncWhiteboardTags, WSSendMessage, WSSendGood}  = useWebSocket()

  const textareaRef = useRef(null)

  // const [, dropRef] = useDrop({
  //   accept: [
  //     "BOX",
  //   ],
  //   drop: (item: any, target) => {
  //     // console.log(item)
  //     if (item.type === "BOX") {
  //       console.log('DROPPED')
  //       setShareData(item);
  //     }
  //   },
  //   // canDrop: (item, target) => {
  //   //   return true;
  //   // },
  //   canDrop: (item, target) => {
  //     // @todo: バグ多い
  //     if (item.type === "BOX") {
  //       return true;
  //       //   return canBookBedFixedDay(date, item, target);
  //       // }else if(item.type === DraggableTypes.EXPANDING_HANDLE){
  //       //   return canExpandBed(date, item, props.bed_id);
  //       //   // return canExpandBed(date, item, target);
  //     }
  //     return true;
  //   },
  //   hover:(item,monitor) => {
  //     // if(item.type === DraggableTypes.BOOKING_LIST){
  //     //
  //     //   setDragOverCell({
  //     //     cursor_bed_id: props.bed_id,
  //     //     cursor_date_id: props.cell_index,
  //     //     update_date_start: props.cell_index,
  //     //     between: (item.bed_date !== undefined) ? item.bed_date.between : null,
  //     //   })
  //     // }
  //   },
  //   collect: (monitor) => {
  //     return {
  //       // isOver: !! monitor.isOver(),
  //       // canDrop: false,
  //       // canDrop: !! monitor.canDrop(),
  //     }
  //   }
  // })

  const sendMessage = useCallback((event:FormEvent) => {
    event.preventDefault()

    // if(shareData.title !== undefined && shareData.title){
    //   ws_send(JSON.stringify({
    //     event_type: EVENT_WEBSOCKET_SHARE_PAGE,
    //     time: new Date(),
    //     user: me,
    //     currentRoom: currentRoom,
    //     shareData: shareData,
    //   } as TYPE_WEBSOCKET_SHARE_PAGE))
    //   setShareData({} as TDropSearchShare);
    // }

    if(message !== ''){

      sha256(message)
        .then((hash) => {
          console.log(hash)

          setTimeline((prevState:any)=>{
            return {
              ...prevState,
              [currentRoom.slug]: [
                ...prevState[currentRoom.slug],
                {
                  chatlog_type: 'MESSAGE',
                  hash: hash,
                  user: me,
                  body: message,
                  date: new Date(),
                }
              ]
            };
          });
          console.log('PLAY')
          console.log(sound)
          sound.play();
          console.log("PLAYED")

          // Using: Kuromoji
          kuromoji.builder({ dicPath: "/kuromoji_dict" })
            .build((err, tokenizer) => {
                if(err){
                  console.log("err")
                  console.log(err)
                } else {
                  const tokens = tokenizer.tokenize(message)
                  // console.log(tokens)

                  const filtered_words = tokens.filter((w:any,idx:number)=>{
                    if (w.pos === "名詞"
                      && (w.pos_detail_1 === "固有名詞"
                        || w.pos_detail_1 === "一般")
                    ){
                      return true
                    }
                    return false
                  })

                  const words = filtered_words.map((w,idx)=>{
                    return w.surface_form
                  })
                  const _send_payload = {
                      event_type: EVENT_WEBSOCKET_SEND_MESSAGE,
                      time: new Date(),
                      user: me,
                      hash: hash,
                      currentRoom: currentRoom,
                      message:message,
                      words: words,
                      current_word: words[0] ?? null
                  } as TYPE_WEBSOCKET_SEND_MESSAGE

                  WSSendMessage(_send_payload)
                  WSSyncWhiteboardTags(words)


                  addIdeaTags(words)
                  setTimeline((prevState:any)=>{

                    return {
                      ...prevState,
                      [currentRoom.slug]: prevState[currentRoom.slug].map((item:any,idx:number)=>{
                        if(hash === item.hash){
                          return {
                            ...item,
                            words: words,
                            current_word: words[0] ?? null
                          }
                        }
                        return item
                      })
                    };
                  });

                }
              }
            )

          // Using: Tiny Segmenter
          // const segs = tinySegmenter.segment(message);
          // // console.log(segs);
          //
          // const reg = new RegExp(/^[あ-んア-ンa-zA-Z0-9、。 　_,-/.()[]=+\*\?<>]$/,'g');
          // let filtered_words = segs.filter((ele:any, idx:number)=>{
          //   if(ele.match(reg)){
          //     return false;
          //   }
          //   return true;
          // })
          // // console.log(filtered_words);
          //
          //
          // ws_send(JSON.stringify({
          //   event_type: EVENT_WEBSOCKET_SEND_MESSAGE,
          //   time: new Date(),
          //   user: me,
          //   currentRoom: currentRoom,
          //   message:message,
          //   words: filtered_words,
          // } as TYPE_WEBSOCKET_SEND_MESSAGE))

          setMessage('')

        })

    }

  },[rwsRef, addIdeaTags, message, me, currentRoom, setTimeline]);


  const sendGood = useCallback((event:FormEvent) => {
    event.preventDefault()

      WSSendGood({
          event_type: EVENT_WEBSOCKET_SEND_GOOD,
          time: new Date(),
          user: me,
          currentRoom: currentRoom
      } as TYPE_WEBSOCKET_SEND_GOOD)


  },[me, currentRoom, rwsRef]);

  // const shareEle = useMemo(()=>{
  //   if(shareData.title !== undefined && shareData.title){
  //     return (
  //       <div className={styles.attachment}>
  //       <span className={styles.attachment_close_button} onClick={(e)=>{
  //         return setShareData({} as TDropSearchShare);
  //       }}>×</span>
  //         <SearchResult
  //           // key="1"
  //           title={shareData.title}
  //           url={shareData.url}
  //           description={shareData.description}
  //           inject_style={styles}
  //         />
  //       </div>
  //     )
  //   }
  //   return null
  // },[shareData, setShareData]);

  return (
    // <div ref={dropRef} className={styles.form}>
    <section className={styles.form}>
      <div className={styles.input_block}>
        {/*<aside>{shareEle}</aside>*/}
        <textarea
          ref={textareaRef}
          className={styles.input_textarea}
          value={message}
          rows={1}
          placeholder={'メッセージを入力'}
          // autoComplete="off"
          onKeyDown={(e) => {
            // 送信する処理
            // // Shift+Enter：改行
            // // Enter：送信
            // let code;
            // if (e.key !== undefined) {
            //   code = e.key;
            // } else if (e.keyCode !== undefined) {
            //   code = e.keyCode;
            // }
            if(e.keyCode === 13 && !e.shiftKey){
              sendMessage(e)
            }
          }}
          onChange={(e) => {
            //Textareaの高さを変える
            if(textareaRef !== null){
              // @ts-ignore
              textareaRef.current.rows = 1;
              // @ts-ignore
              while (textareaRef.current.scrollHeight > textareaRef.current.offsetHeight) {
                // @ts-ignore
                const tempRows = Number(textareaRef.current.rows);
                // @ts-ignore
                textareaRef.current.rows = tempRows + 1;
              }
            }
            setMessage(e.target.value)
          }}
        />

        <button type={'button'}
          onClick={(e)=>{
            sendMessage(e)
          }}
        >Send2</button>
        <div className={styles.icn_face}
           onClick={(e)=>{
             sendMessage(e)
           }}
        >
          <img src="/img/icn_face.svg" alt=""/>
        </div>
      {/*  */}
      </div>
      <div className={styles.icn_good}
         onClick={(e)=>{
           sendGood(e)
         }}
      >
        <img src="/img/icn_good.svg" alt=""/>
      </div>
    </section>
  );
}


export async function sha256(text:string){
  const uint8  = new TextEncoder().encode(text)
  const digest = await crypto.subtle.digest('SHA-256', uint8)
  return Array.from(new Uint8Array(digest)).map(v => v.toString(16).padStart(2,'0')).join('')
}