import React, {useEffect, useMemo, useState} from 'react';
// import {useDrag} from "react-dnd";
// import {TDropSearchShare} from "../../../../types/TDrop";

import styles from './SearchResult.module.css'
import {API_HOST} from "../../../../config/config";

interface Props {
  title: string
  url: string
  img_url?: string
  description?: string
  inject_style?: any
  isMine?: boolean
  onContextMenu?: any
}


export const SearchResultDetail: React.FC<Props> = (
  {
    children,
    title,
    url,
    img_url,
    description,
    inject_style,
    isMine,
    onContextMenu
  }) => {


  const [imgSrc, setImgSrc] = useState('http://placehold.jp/200x150.png?text=NOIMAGE')

  useEffect(() => {
    let u;
    if (img_url !== undefined && img_url !== null && img_url !== "") {
      u = encodeURI(img_url)
      setImgSrc(API_HOST + '/v0/img_url?url=' + u);
    } else {
      u = encodeURI(url)
      setImgSrc(API_HOST + '/v0/img_weburl?url=' + u);
      // u = encodeURI('https://www.mcdonalds.co.jp/media_library/7044/file.original.jpg?20210122035948')
    }

  }, [img_url, setImgSrc, url])

  const current_theme = inject_style ?? styles;

  const search_result_class = useMemo(() => {
    if (isMine) {
      return current_theme.your_search_result
    }
    return current_theme.search_result
  }, [isMine, current_theme])

  return (
    <div className={search_result_class} onContextMenu={onContextMenu}>
      <a href={url} target="_blank" rel="noreferrer"
         className={current_theme.image}
      >
        {/*NO IMAGE*/}
        <img src={imgSrc} alt=""/>
      </a>
      <p className={current_theme.title}>{title}</p>
      <p className={current_theme.description}>{description}</p>
      <p className={current_theme.button_wrap}><a href={url} target="_blank" rel="noreferrer">
        詳しく見る
        {/*<img src={imgurl} alt=""/>*/}
      </a></p>
      {children}
    </div>
  );
}
