import React from 'react';

import styles from './RoomHeader.module.css'


export const FunctionMenu:React.FC = () => {

  return (
    <nav className={styles.functionMenu}>
      <ul>
        {/*<li><a href="#">設定</a></li>*/}
        {/*<li><a href="#">フィードバック</a></li>*/}
        {/*<li><a href="#">ヘルプ</a></li>*/}
        {/*<li><a href="#">利用規約・プライバシーポリシー</a></li>*/}
        <li><a href="https://socialis.co.jp/" target={"_blank"}>運営会社</a></li>
        {/*<li><a href="#">ログアウト</a></li>*/}
      </ul>
    </nav>
  );
}
