import React, {useContext, useState} from "react";

import {IUser} from "../../types/TChat";
import {MeContext} from "../../context/MeProvider";

import {EntranceWrap} from "./Components/EntranceParts";

import styles from "./Entrance.module.css";
import {
  EVENT_WEBSOCKET_ENTER_ROOM, EVENT_WEBSOCKET_INIT,
  TYPE_WEBSOCKET_ENTER_ROOM, TYPE_WEBSOCKET_INIT,
} from "../../types/TWebSocket";
import {WebSocketContext} from "../../context/WebSocketProvider";


export const EntranceIcon: React.FC = () => {

  const {me, setMe, currentRoom} = useContext(MeContext);
  const {rwsRef} = useContext(WebSocketContext);

  return (
    <EntranceWrap>
      <ProfileIcons/>
      <button
        onClick={(event) => {
          if (me.icon !== undefined && me.icon.r !== undefined) {
            setMe((prevState: any) => {
              return {
                ...prevState,
                enter: true
              }
            })

            rwsRef?.current?.send(JSON.stringify({
              event_type: EVENT_WEBSOCKET_INIT,
              time: new Date(),
              user: me,
              user_id: me.user_id,
              currentRoom: currentRoom
            } as TYPE_WEBSOCKET_INIT))

            rwsRef?.current?.send(JSON.stringify({
              event_type: EVENT_WEBSOCKET_ENTER_ROOM,
              time: new Date(),
              user: me,
              currentRoom: currentRoom
            } as TYPE_WEBSOCKET_ENTER_ROOM))

          }
        }}
        disabled={me.icon === undefined || me.icon.r === undefined}
      >ENTER ROOM
      </button>
    </EntranceWrap>
  )
}


const ProfileIcons: React.FC = () => {

  const {setMe} = useContext(MeContext);
  const [tmpColor, setTmpColor] = useState<string>('000000')

  // const currentIcon = useMemo(() => {
  //   if (me === undefined || me.icon === undefined || me.icon.id === undefined) {
  //     return null
  //   }
  //   const init_icon: IProfileIcon = me.icon
  //   return (
  //     <li><i
  //       className={init_icon.id === tmpColor ? styles.active_icon : ''}
  //       style={{backgroundColor: 'rgba(' + init_icon.r + ',' + init_icon.g + ',' + init_icon.b + ',1)',}}
  //       onClick={(e) => {
  //         setTmpColor(init_icon.id)
  //         setMe((prevState: IUser) => {
  //           // console.log(2,r,g,b)
  //           return {
  //             ...prevState,
  //             icon: {
  //               id: init_icon.id,
  //               r: init_icon.r,
  //               g: init_icon.g,
  //               b: init_icon.b
  //             }
  //           }
  //         })
  //       }}
  //     /></li>
  //   )
  // }, [me])

  return (<>
    <p className={styles.txt}>アイコンを選択</p>
    <ul className={styles.icons}>
      {/*{currentIcon}*/}

      {
        colors.map((color, idx) => {
          return (<li key={color.id}><i
            className={color.id === tmpColor ? styles.active_icon : ''}
            style={{backgroundColor: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',1)',}}
            onClick={(e) => {
              setTmpColor(color.id)
              setMe((prevState: IUser) => {
                // console.log(2,r,g,b)
                return {
                  ...prevState,
                  icon: {
                    id: color.id,
                    r: color.r,
                    g: color.g,
                    b: color.b
                  }
                }
              })
            }}
          ><img src="/img/icn_profile.svg" alt=""/></i></li>)
        })
      }
    </ul>
  </>)
}


const colors = [0, 1, 2, 3, 4, 5, 6, 7].map((k, idx) => {
  const r = Math.floor(Math.random() * 255)
  const g = Math.floor(Math.random() * 255)
  const b = Math.floor(Math.random() * 255)
  return {
    id: r.toString(16)
      + g.toString(16)
      + b.toString(16),
    r: r,
    g: g,
    b: b
  }
})
