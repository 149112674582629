import React, {useRef, useEffect, useState, useContext, useCallback} from 'react';

import {StickyNoteTextarea} from "./StickyNoteTextarea";
import {Tag} from "./canvas_components/Tag";
import {StickyNote} from "./canvas_components/StickyNote";
import {TMousePosition} from "./Types/TMousePosition";
import {TDraggableObject, TSourceStickyNote} from "./Types/TSourceStickyNote";
import styles from './WhiteboardContent.module.css'
import {WhiteboardContext} from "../../../../context/WhiteboardProvider";
import {useCursor} from "./hooks/useCursor";
import {useWebSocket} from "../../../../services/useWebSocket";
import {ButtonDeleteAll} from "./canvas_components/ButtonDeleteAll";



export const WhiteboardContent:React.FC = () => {

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvascontext, setCanvascontext] = useState<CanvasRenderingContext2D | null>(null);
  const [rect, setRect] = useState<DOMRect>();
  const [canvasDimensions, setCanvasDimensions] = useState<{width:number, height:number}>({
    width: window.innerWidth * 0.5 - 2,  // minus 2 pixels
    height: window.innerHeight - 140  // minus 140 pixels
  });

  const [lastMousePos, setLastMousePos] = useState<TMousePosition>({ x: 0, y: 0 });

  const {stickyNotes, setStickyNotes,
    tagIdeas,
    editingNote, setEditingNote,
    draggingObject, setDraggingObject,
    addNote, removeIdeaTag
  } = useContext(WhiteboardContext)

  const {checkIfMouseIsOverTag} = useCursor(canvasRef, canvascontext, rect);
  const {WSSyncWhiteboardNotes} = useWebSocket();

  useEffect(() => {
    if (canvasRef.current) {

      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx2d = canvas.getContext('2d');
      if (ctx2d) {
        // ctx2d.scale(window.devicePixelRatio, window.devicePixelRatio);
        // ctx2d.scale(scale, scale);
        setCanvascontext(ctx2d);
      }

      const canvasRect = canvas.getBoundingClientRect();
      if(canvasRect){
        setRect(canvasRect);
      }

    }
  }, [setCanvascontext, setRect, canvasRef]); // 空の依存配列を渡すことで、この useEffect ブロックはコンポーネントがマウントされた後に一度だけ実行されます


  /**
   * 描画
   */
  const draw = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas === null || canvascontext === null) return;
    canvascontext.clearRect(0, 0, canvas.width, canvas.height);


    //付箋
    stickyNotes.forEach(stickyNote => {
      if(
          draggingObject
          && draggingObject.type === "STICKY_NOTE"
          && stickyNote.id === draggingObject.id
      ){
        new StickyNote(draggingObject.x, draggingObject.y, stickyNote.width, stickyNote.height, stickyNote.color, stickyNote.text)
            .draw(canvascontext);
      }else{
        new StickyNote(stickyNote.x, stickyNote.y, stickyNote.width, stickyNote.height, stickyNote.color, stickyNote.text)
            .draw(canvascontext);
      }
    });

    //タグ
    tagIdeas.map((tagIdea,idx) => {
      return new Tag(tagIdea,idx);
      // object.draw(canvascontext)
    }).forEach((item, idx)=>{
      if(draggingObject
          && draggingObject.type === "IDEA_TAG"
          && draggingObject.idx === item.idx
      ){
        item.moveAndDraw(canvascontext, draggingObject.x, draggingObject.y)
      }else{
        item.draw(canvascontext)
      }

    });

    // new ButtonDeleteAll().draw(canvascontext)

    // 文字列の描画
    canvascontext.font = "bold 16px Noto Sans JP";  // 文字列のスタイルを設定（フォントサイズやフォントファミリーなど）

    canvascontext.fillStyle = "#000000";  // 文字列の色を変更
    const textWidth = canvascontext.measureText('抽出キーワード').width;
    canvascontext.fillText('抽出キーワード', 25, 45);  // 文字列を描画


  },[tagIdeas, stickyNotes, canvasRef, canvascontext, draggingObject]);



  useEffect(() => {
    draw()
  }, [canvascontext, stickyNotes, tagIdeas, draw]); // 空の依存配列を渡すことで、この useEffect ブロックはコンポーネントがマウントされた後に一度だけ実行されます

  const handleMouseDown = (e:React.MouseEvent) => {
    if (rect === null || rect === undefined) return;

    const pos = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };

    for (const note of stickyNotes) {
      if (pos.x > note.x
        && pos.x < note.x + note.width
        && pos.y > note.y
        && pos.y < note.y + note.height
      ) {
        setDraggingObject({
          ...note,
          type:"STICKY_NOTE"
        } as TDraggableObject);
        setLastMousePos(pos);
      }
    }

    // // ノートにhoverしてるか
    // const hoverd_notes = stickyNotes.map((stickyNote,idx) => {
    //   return new StickyNote(stickyNote.x, stickyNote.y, stickyNote.width, stickyNote.height, stickyNote.color, stickyNote.text);
    //
    // }).filter(note=>{
    //   return note.contains(pos)
    // });
    // if(hoverd_notes.length > 0){
    //   setDraggingObject({
    //     x: hoverd_notes[0].x,
    //     y: hoverd_notes[0].y,
    //     width: hoverd_notes[0].width,
    //     height: hoverd_notes[0].height,
    //     text: hoverd_notes[0].text,
    //     color: ""
    //   } as TDraggableObject);
    //   setLastMousePos(pos);
    // }


    const hoverd_tagideas = tagIdeas.map((tagIdea,idx) => {
      return new Tag(tagIdea,idx);
      // object.draw(canvascontext)
    }).filter(tag=>{
      return tag.contains(pos)
    });
    if(hoverd_tagideas.length > 0){

      setDraggingObject({
        x: hoverd_tagideas[0].x,
        y: hoverd_tagideas[0].y,
        width: hoverd_tagideas[0].width,
        height: hoverd_tagideas[0].height,
        text: hoverd_tagideas[0].text,
        type:"IDEA_TAG",
        idx: hoverd_tagideas[0].idx
      } as TDraggableObject);

      setLastMousePos(pos);
    }

  };

  const handleMouseMove = (e:React.MouseEvent) => {
    if (draggingObject === null) return;
    if (rect === null || rect === undefined) return;

    const pos = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };
    draggingObject.x += pos.x - lastMousePos.x;
    draggingObject.y += pos.y - lastMousePos.y;
    setLastMousePos(pos);
    draw();
  };


  const handleMouseUp = (e:React.MouseEvent) => {
    if (rect === null || rect === undefined) return;
    if (draggingObject === null) return;

    if(draggingObject){

      const pos = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      };

      if( draggingObject.type === "STICKY_NOTE"){
        const updatedStickyNotes = [...stickyNotes].map((prevNote)=> {
              if (prevNote.id === draggingObject.id) {
                return {
                  ...draggingObject,
                  x: draggingObject.x += pos.x - lastMousePos.x,
                  y: draggingObject.y += pos.y - lastMousePos.y,
                }
              }
              return prevNote
            }
        )
        setStickyNotes(updatedStickyNotes)
        WSSyncWhiteboardNotes(updatedStickyNotes)

      } else if( draggingObject.type === "IDEA_TAG") {
        addNote(draggingObject.text,
            draggingObject.x,
            draggingObject.y)
        removeIdeaTag(draggingObject.text)
      }
    }

    setDraggingObject(null);
  };

  const handleDoubleClick = (e:React.MouseEvent) => {
    // 編集中の付箋がある場合は何もしない

    if (rect === null || rect === undefined) return;

    const pos = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };

    if(canvascontext){

      let is_inside_object = false;
      // クリックされた付箋を見つける
      for (const note of stickyNotes) {
        if (pos.x > note.x
          && pos.x < note.x + note.width
          && pos.y > note.y
          && pos.y < note.y + note.height
        ) {
          is_inside_object = true;
          setEditingNote(note);
        }
      }

      if(!is_inside_object){
        const new_note = { x: pos.x-75, y: pos.y-50, width: 150, height: 100, color: '#FFD700', text: '' };
        setStickyNotes((prevNotes:TSourceStickyNote[])=>{
          return [...prevNotes, new_note]
        })
      }

    }
  };



  // サイズ変更を監視
  useEffect(() => {
    const handleResize = () => {
      // ここでcanvasの描画を行う
      setCanvasDimensions({
        width: window.innerWidth * 0.5 - 2,  // minus 2 pixels
        height: window.innerHeight - 140  // minus 140 pixels
      });
    }

    if (canvasRef.current) {
      canvasRef.current.addEventListener('mousemove', checkIfMouseIsOverTag as any);
      canvasRef.current.addEventListener('touchmove', checkIfMouseIsOverTag as any);
    }

    window.addEventListener('resize', handleResize);

    // cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);

      if (canvasRef.current) {
        canvasRef.current.removeEventListener('mousemove', checkIfMouseIsOverTag as any);
        canvasRef.current.removeEventListener('touchmove', checkIfMouseIsOverTag as any);
      }
    };
  }, [checkIfMouseIsOverTag]);


  useEffect(() => {
    if (canvasRef.current)
    {
      canvasRef.current.width = canvasDimensions.width;
      canvasRef.current.height = canvasDimensions.height;
      draw()
    }
  }, [canvasDimensions, draw]);


  return (
    <div className={styles.whiteboard_content} style={{

    }}>
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onDoubleClick={handleDoubleClick}
      />
      {editingNote && (
        <StickyNoteTextarea />
      )}

      {/*<button id="print_btn">Print</button>*/}
    </div>
  );
}
