import React, {useContext} from 'react';

import {TChatLog} from "../../../../types/TChat";
import {SearchResultDetail} from "../SearchResult/SearchResultDetail";

import {TDropSearchShare} from "../../../../types/TDrop";
import {MeContext} from "../../../../context/MeProvider";
import {useChatMessage} from "../../../../services/useChatMessage";
import {Good} from "./Good";

import search_result_styles from './MessageDetailSearchResult.module.css'
import {MessageDropWrap} from "./MessageDropWrap";
import {useContextMenu} from "react-contexify";


interface Props {
  chatLog: TChatLog
}


export const MessagePage:React.FC<Props> = ({chatLog}) => {

  const {me, setContextMenuText, setContextMenuChatLog} = useContext(MeContext);
  const {isMine} = useChatMessage({me, chatLog})

  const CONTEXTMENU_ID_CHAT = 'CHAT_CONTEXTMENU';
  const { show } = useContextMenu({
    id: CONTEXTMENU_ID_CHAT,
  });

  function displayContextMenu(e:any){
    const selection = window.getSelection();
    if(selection !== undefined && selection !== null){
      console.log(selection.toString());
      setContextMenuText(selection.toString());
      setContextMenuChatLog(chatLog)
    }else{
      setContextMenuText('');
      setContextMenuChatLog({} as TChatLog)
    }
    show(e);
  }

  let l:TDropSearchShare = chatLog.page as TDropSearchShare;

  return (
    <MessageDropWrap chatLog={chatLog}>
      <SearchResultDetail url={l.url}
                          title={l.title}
                          description={l.description}
                          inject_style={search_result_styles}
                          isMine={isMine}
                          onContextMenu={displayContextMenu}
      >
      </SearchResultDetail>
      <Good chatLog={chatLog} />
    </MessageDropWrap>
  );
}

