import * as React from "react";
import {useCallback, useContext} from "react";
import {WhiteboardContext} from "../../../../../context/WhiteboardProvider";
import {Tag} from "../canvas_components/Tag";
import {StickyNote} from "../canvas_components/StickyNote";

// import {ModalContext} from "../context/ModalProvider";

export interface useCursorProps {
  checkIfMouseIsOverTag: Function
}

export const useCursor = (canvasRef:any, canvascontext:CanvasRenderingContext2D|null, rect:DOMRect|undefined): useCursorProps => {


  const {
    tagIdeas,
      stickyNotes,
      draggingObject
  } = useContext(WhiteboardContext)

  const checkIfMouseIsOverTag =  useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>):void => {

    // console.log(e.clientX, e.clientY)

    if(!rect) return ;

    const pos = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };

    const is_hover_tagidea = tagIdeas.some((tagIdea,idx) => {
      return new Tag(tagIdea,idx)
        .contains(pos);
      // object.draw(canvascontext)
    });

    const is_hover_stickynote = stickyNotes.some((stickyNote,idx) => {
      return new   StickyNote(stickyNote.x, stickyNote.y, stickyNote.width, stickyNote.height, stickyNote.color, stickyNote.text)
          .contains(pos);
      // object.draw(canvascontext)
    });
    if(draggingObject){
      canvasRef.current.style.cursor = 'grabbing';
    } else if (is_hover_tagidea) {
      canvasRef.current.style.cursor = 'grab';
    } else if(is_hover_stickynote){
      canvasRef.current.style.cursor = 'grab';
    }else {
      canvasRef.current.style.cursor = 'default';  // それ以外の場合、カーソルをデフォルトに戻す
    }
  }, [canvasRef, rect, tagIdeas, stickyNotes, draggingObject]);


  return {
    checkIfMouseIsOverTag
  }

}
