import React, {useEffect, useState} from 'react';
import {useDrag} from "react-dnd";

import {TDropSearchShare} from "../../../../types/TDrop";

import styles from './SearchResult.module.css'
import {API_HOST} from "../../../../config/config";

interface Props {
  title: string
  url: string
  img_url ?: string
  description ?: string
  inject_style?: any
  isYou?: boolean
}

export const SearchResult:React.FC<Props> = ({title, url,img_url, description, inject_style}) => {

  // const url = 'http://placehold.jp/200x150.png';
  // let img_src = 'http://placehold.jp/200x150.png';

  const [imgSrc, setImgSrc] = useState( 'http://placehold.jp/200x150.png?text=NOIMAGE')

  useEffect(()=>{
    let u;
    if(img_url !== undefined && img_url !== null && img_url !== ""){
      u = encodeURI(img_url)
      setImgSrc( API_HOST+'/v0/img_url?url='+u);
    }else {
      u = encodeURI(url)
      setImgSrc( API_HOST+'/v0/img_weburl?url='+u);
      // u = encodeURI('https://www.mcdonalds.co.jp/media_library/7044/file.original.jpg?20210122035948')
    }

    // const f = async () => {
    //   const res_keyword = await fetch(
    //     '/v0/img/123'
    //     // 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/Washington_Montage_2016.png/500px-Washington_Montage_2016.png'
    //     // url
    //     , {
    //     method: 'GET',
    //     headers: {
    //       // mode: 'no-cors',
    //       // "Access-Control-Allow-Origin": "*",
    //       // 'Content-Type': 'application/json',
    //       //   Authorization: `Bearer ${cookies.access_token}`,
    //     },
    //   });
    //
    //   console.log(1);
    //   const img_blob = await res_keyword.blob()
    //   const u = encodeURI('https://www.mcdonalds.co.jp/media_library/7044/file.original.jpg?20210122035948')
    //
    //   setImgSrc( API_HOST+'/v0/img_url?url='+u);
    //   // setImgSrc( URL.createObjectURL(img_blob));
    //
    //   if (res_keyword.status === 200) {
    //     // let json = await res_keyword.json()
    //
    //     // if( json !== null){
    //     //
    //     //   const result_items = [...json]
    //     //     .sort((a,b) => { return Math.random() - .5; })
    //     //   ;
    //     //
    //     //   setPages((prevState:any)=>{
    //     //     return {
    //     //       ...prevState,
    //     //       [word]: result_items ?? []
    //     //     };
    //     //   });
    //     // }
    //
    //   }
    // };
    // f();

  },[img_url, setImgSrc, url])

  const current_theme = inject_style ?? styles;

  const [{ isDragging }, dragRef] = useDrag({
    item: {
      type: "BOX",
      title: title,
      description: description ?? '',
      url: url,
      imgurl: imgSrc,
    } as TDropSearchShare,
    // end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
    //   const dropResult = monitor.getDropResult()
    //   if (item && dropResult) {
    //     alert(`You dropped ${item.name} into ${dropResult.name}!`)
    //   }
    // },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.7 : 1

  return (
    <div ref={dragRef} className={current_theme.search_result} style={{ opacity }}>
      <a href={url} target="_blank" rel="noreferrer"
         className={current_theme.image}
      >
        {/*NO IMAGE*/}
        <img src={imgSrc} alt="" />
      </a>
      <p>{title}</p>
    </div>
  );
}

