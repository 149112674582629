import React, {useContext} from 'react';

import {TChatLog} from "../../../../types/TChat";

import {MeContext} from "../../../../context/MeProvider";
import {useChatMessage} from "../../../../services/useChatMessage";
import {MessageDropWrap} from "./MessageDropWrap";
import {Good} from "./Good";

import styles from './MessageText.module.css'
// import {formatDistanceToNow} from "date-fns";
// import {ja} from "date-fns/locale";
// import wrap_styles from './Message.module.css'
import {useContextMenu} from "react-contexify";

interface Props {
  chatLog: TChatLog
}

export const MessageText:React.FC<Props> = ({chatLog }) => {

  const {me, setContextMenuText, setContextMenuChatLog} = useContext(MeContext);

  const {isMine} = useChatMessage({me, chatLog})

  const CONTEXTMENU_ID_CHAT = 'CHAT_CONTEXTMENU';
  const { show } = useContextMenu({
    id: CONTEXTMENU_ID_CHAT,
  });

  function displayContextMenu(e:any){
    const selection = window.getSelection();
    if(selection !== undefined && selection !== null){
      console.log(selection.toString());
      setContextMenuText(selection.toString());
      setContextMenuChatLog(chatLog)
    }else{
      setContextMenuText('');
      setContextMenuChatLog({} as TChatLog)
    }
    show(e);
  }

  return (
    <MessageDropWrap chatLog={chatLog}>
      <div className={isMine
        ? styles.my_body_message_box
        : styles.body_message_box
      }>
        <p className={styles.body_message}
           onContextMenu={displayContextMenu}
        >
          {chatLog.body}
        </p>
        <Good chatLog={chatLog} />
      </div>
    </MessageDropWrap>
  );
}



// export const MessageText2:React.FC<Props> = ({chatLog,pages,user}) => {
//
//   const [, dropRef] = useDrop({
//     accept: [
//       "BOX",
//     ],
//     drop: (item: any, target) => {
//       // console.log(item)
//       if (item.type === "BOX") {
//         console.log('DROPPED')
//         // setShareData(item);
//
//         ws_send(JSON.stringify({
//           event_type: EVENT_WEBSOCKET_SHARE_PAGE,
//           time: new Date(),
//           user: {name:'AAA'},
//           currentRoom: currentRoom
//           message: item,
//         }))
//       }
//     },
//     canDrop: (item, target) => {
//       if (item.type === "BOX") {
//         return true;
//       }
//       return true;
//     },
//     hover:(item,monitor) => {
//     },
//     collect: (monitor) => {
//       return {
//       }
//     }
//   })
//
//   return (
//     <div className={styles.message} ref={dropRef}>
//       <div className={styles.chat_container}>
//         <div className={styles.meta}>
//           <span style={{color:
//               user.name === chatLog.user.name ? "red" : 'inherit'
//           }} className={styles.name}>{chatLog.user.name}</span>
//           <span className={styles.time}>{formatDistanceToNow(chatLog.date, { locale: ja })}</span>
//           <span>書き込み中</span>
//         </div>
//         <p className={styles.body} style={{backgroundColor:"#DDDDDD;",color: "#999999;"}}>{chatLog.body}</p>
//
//       </div>
//     </div>
//   );
// }
