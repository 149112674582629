import React, {useContext, useEffect, useMemo} from 'react'

import {MeContext} from "../../../context/MeProvider";
import {TimelineContext} from "../../../context/timelineProvider";
import {TChatLog} from "../../../types/TChat";
import {ModalWrapper} from "./ModalWrapper";

import styles from './Modal.module.css'

export const ExportModal: React.FC = () => {

  const {currentRoom} = useContext(MeContext);
  const {timeline} = useContext(TimelineContext);


  useEffect(()=>{
    if (typeof Blob !== "undefined") {
      // alert('このブラウザに対応しています');
    } else {
      alert('このブラウザには対応していません');
    }
  },[])

  const buildUrlForOutputText = useMemo(()=>{
    let logTexts = ""
    const currentTimeline = timeline[currentRoom.slug];
    if(currentTimeline === null || currentTimeline === undefined){
      logTexts = ""
    }else{

      const logs = currentTimeline.map((chatLog:TChatLog, idx: number)=>{

        if(chatLog.chatlog_type === "BOT"){
          return {
            date: chatLog.date,
            name: chatLog.user.name,
            message: '入室'
          }
        } else if(chatLog.chatlog_type === "MESSAGE"){
          return {
            date: chatLog.date,
            name: chatLog.user.name,
            message: chatLog.body
          }
        } else if(chatLog.chatlog_type === "PAGE"){
          if(chatLog.page !== undefined && chatLog.page !== null)
          return {
            date: chatLog.date,
            name: chatLog.user.name,
            message:
              chatLog.page.title + "\n"
            + chatLog.page.url + "\n"
            + chatLog.page.description
            // title,
            // url,
            // img_url,
            // description,
          }
        } else if(chatLog.chatlog_type === "GOOD"){
          return {
            date: chatLog.date,
            name: chatLog.user.name,
            message: 'いいね'
          }
        }
        return {
          date: new Date(),
          name: '',
          message: ''
        }
      })

      logs.forEach((item:any,idx:number)=>{
        logTexts += "--------------------\n"
          + item.date + "\n"
          + item.name + "\n"
          + item.message + "\n\n"
      })
    }

    let blob = new Blob(['議事録：'+currentRoom.title + "/n/n" + logTexts],{type:"text/plan"});
    // let link = document.createElement('a');
    // console.log(URL.createObjectURL(blob));
    return URL.createObjectURL(blob);
    // link.click();
  },[timeline, currentRoom])

  return (
    <ModalWrapper modal_key={"EXPORT"}>
      <div className={styles.modal_content}>
        <h2>議事録を出力</h2>
        <nav className={styles.exports}>
          <ul>
            <li onClick={(e)=>{
              console.log('テキストファイルで出力')
            }}><a href={buildUrlForOutputText} download={'議事録:' + currentRoom.title + '.txt'}>
              テキスト出力
            </a></li>
            <li><a href="#1" onClick={(e)=>{
              e.preventDefault()
              console.log('HTML出力')
              alert('HTML出力できるようになる予定')
            }}>HTML出力</a></li>
            <li><a href="#1" onClick={(e)=>{
              e.preventDefault()
              console.log('PDF出力')
              alert('PDF出力できるようになる予定')
            }}>PDF出力</a></li>
          </ul>
        </nav>
      </div>
    </ModalWrapper>
  )
}
